<template>
  <div class="see-users-container">
    <!-- Sidebar with Tabs -->
    <div class="sidebar">
      <div class="left">
        <ul>
          <li v-for="tab in tabs" :key="tab.id" @click="changeTab(tab.id)"
            :class="{ 'active-user-link': activeTabId === tab.id }">
            {{ tab.name }}
          </li>
          <li @click="showAdminsTab" :class="{ 'active-user-link': activeTabId === 'admins' }">
            Admins
          </li>
        </ul>
      </div>

      <!-- Search Bar -->
      <div class="right">
        <div class="input-container">
          <input type="text" v-model="searchQuery" placeholder="Search" />
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#D3E4FF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="see-users-body">
      <div v-if="loading" class="spinner-container">
        <loading_spinner />
      </div>

      <div v-else class="card-container">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Names</th>
              <th>Phone</th>
              <th v-if="activeTabId === 'admins'">Actions</th>
              <template v-else>
                <th>NID</th>
                <th>Gender</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-if="users.length === 0">
              <td :colspan="activeTabId === 'admins' ? 4 : 6" class="no-records-row">
                No records found.
              </td>
            </tr>

            <tr v-else-if="activeTabId === 'admins'" v-for="(user, index) in displayedUsers" :key="user.id">
              <td>{{ calculateIndex(index) }}</td>
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.phone }}</td>
              <td>
                <button class="permission-button"
                  @click="viewPermissions(user.id, user.first_name + ' ' + user.last_name)">
                  View Permissions
                </button>
              </td>
            </tr>

            <tr v-else v-for="(user, index) in displayedUsers" :key="user.id">
              <td>{{ calculateIndex(index) }}</td>
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.phone }}</td>
              <td>{{ user.nid || "N/A" }}</td>
              <td>{{ user.gender }}</td>
            </tr>
          </tbody>
        </table>

        <!-- Pagination -->
        <div v-if="users.length > 0" class="table-footer">
          <div class="left">
            <button @click="prevPage" :disabled="currentPage === 1">
              Previous
            </button>
            <span>{{ currentPage }} / {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">
              Next
            </button>
          </div>
          <div class="right">
            <p>
              Showing {{ startIndex + 1 }} to {{ endIndex }} of {{ filteredUsers.length }}
            </p>
          </div>
        </div>
      </div>
    </div>


    <!-- Modal -->
    <div class="modal-wrapper">
      <teleport to="body">
        <transition name="fade">
          <div v-if="showModal" class="modal-overlay" @click="closeModal">
            <div class="modal" @click.stop>
              <div class="modal-header">
                <p>Permissions for {{ modalUserName }}</p>
                <svg @click="closeModal" width="36" height="36" viewBox="0 0 36 36" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
                    fill="#757780" />
                </svg>
              </div>
              <div class="modal-body">
                <div v-for="(permissions, type) in permissions" :key="type">
                  <h3>{{ type }}</h3>
                  <ul>
                    <li v-for="permission in permissions" :key="permission.id">
                      {{ permission.description }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </teleport>
    </div>
  </div>
</template>



<script>
import axios from "@/helpers/axios.js";
import { mapGetters } from "vuex";
import loading_spinner from "@/components/loading_spinner.vue";

export default {
  data() {
    return {
      tabs: [],
      activeTabId: null,
      users: [],
      searchQuery: "",
      currentPage: 1,
      itemsPerPage: 10,
      loading: false,
      showModal: false,
      permissions: {},
      modalUserName: "",
    };
  },
  computed: {
    ...mapGetters(["selectedProjectId"]),
    filteredUsers() {
      if (!this.searchQuery) return this.users;
      const regex = new RegExp(this.searchQuery.trim(), "i");
      return this.users.filter(
        (user) => regex.test(user.first_name) || regex.test(user.last_name)
      );
    },
    displayedUsers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredUsers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.itemsPerPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(this.startIndex + this.itemsPerPage, this.filteredUsers.length);
    },
  },
  watch: {
    selectedProjectId: {
      immediate: true,
      handler(newProjectId) {
        if (newProjectId) {
          this.resetData(); // Reset users, tabs, and activeTabId
          this.fetchTabs(newProjectId); // Fetch new tabs
        }
      },
    },
  },
  methods: {
    resetData() {
      this.users = [];
      this.tabs = [];
      this.activeTabId = null;
    },
    async fetchTabs(projectId) {
      this.loading = true;
      try {
        const response = await axios.get(`/teams?project_id=${projectId}`);
        this.tabs = response.data.data || [];
        if (this.tabs.length > 0) {
          this.activeTabId = this.tabs[0].id; // Set default tab
          this.fetchUsers(); // Fetch users for the first tab
        } else {
          this.activeTabId = "admins"; // Fallback to "admins" if no tabs exist
          this.fetchUsers();
        }
      } catch (error) {
        console.error("Error fetching tabs:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchUsers() {
      this.loading = true;
      try {
        const url =
          this.activeTabId === "admins"
            ? `/admin/all/admin?project_id=${this.selectedProjectId}`
            : `/admin/users?project_id=${this.selectedProjectId}&team_id=${this.activeTabId}`;
        const response = await axios.get(url);
        this.users = response.data.data || [];
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        this.loading = false;
      }
    },
    changeTab(tabId) {
      this.activeTabId = tabId;
      this.fetchUsers();
    },
    showAdminsTab() {
      this.activeTabId = "admins";
      this.fetchUsers();
    },
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    async viewPermissions(userId, userName) {
      this.showModal = true;
      this.modalUserName = userName;
      try {
        const response = await axios.get(`/admin/permissions/${userId}`);
        this.permissions = this.groupPermissionsByType(response.data.data);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    },
    groupPermissionsByType(data) {
      return data.reduce((acc, permission) => {
        const { type, description, id } = permission;
        if (!acc[type]) acc[type] = [];
        acc[type].push({ id, description });
        return acc;
      }, {});
    },
    closeModal() {
      this.showModal = false;
      this.permissions = {};
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
  },
  components: {
    loading_spinner,
  },
};
</script>




<style lang="scss" scoped>
.spinner-container {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.see-users-container {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .sidebar {
    width: 100%;
    background-color: #0A2472;
    position: sticky;
    top: 0;
    padding: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 20px;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.active-user-link {
          background-color: white;
          color: #0A2472;
          font-weight: bold;
        }
      }
    }

    .right .input-container {
      position: relative;

      input {
        width: 200px;
        padding: 10px 12px 10px 40px;
        background-color: rgba(255, 255, 255, 0.2);
        border: 1px solid #d3e4ff;
        border-radius: 8px;
        color: white;
        outline: none;

        &::placeholder {
          color: #d3e4ff;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
      }
    }
  }

  .see-users-body {
    width: 100%;
    overflow-x: auto;
  }

  .card-container {
    table {
      width: 100%;
      border-collapse: collapse;
      background-color: white;
      border: 1px solid #e4e7ec;

      thead {
        background-color: #f9fafb;
        color: #344054;
        text-align: left;

        th {
          padding: 15px;
          border-bottom: 1px solid #e4e7ec;
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f9f9f9;
          }

          td {
            padding: 12px;
            border-bottom: 1px solid #e4e7ec;
          }
        }
      }
    }

    .table-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #f9fafb;
      border-top: 1px solid #e4e7ec;

      .left button {
        background-color: #0A2472;
        color: white;
        padding: 5px 10px;
        border: none;
        cursor: pointer;
        border-radius: 5px;

        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }

      .right p {
        margin: 0;
      }
    }
  }
}

.no-records-row {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: #344054;
  background-color: #f9fafb;
}

.modal-wrapper {
  position: relative;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  // backdrop-filter: blur(15px);
  z-index: 99999999;
}

.modal {
  background: white;
  padding: 32px;
  border-radius: 10px;
  transform: scale(1);
  transition: 300ms ease;
  z-index: 99999;
  width: 542px;
  min-height: 258px;

  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: sticky;
    top: 0;

    p {
      font-size: 20px;
      color: #344054;
      font-weight: 400;
    }

    svg {
      cursor: pointer;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;


    .modal-body-container {
      display: flex;
      gap: 30px;
      flex-direction: column;
      gap: 24px;

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 6px;


        label {
          font-size: 14px;
          color: #344054;
        }


        input {
          padding: 12px 14px;
          border-radius: 8px;
          border: 1px solid #D0D5DD;
          outline: 2px solid transparent;
          transition: 200ms ease;
          color: #344054;


          &:focus,
          &:valid {
            outline: 2px solid #5582e2;
          }


          &::placeholder {
            font-size: 16px;
          }
        }

      }

      .btn-container {
        button {
          width: 169px;
          height: 44px;
          border: none;
          border-radius: 8px;


          background-color: rgba(0, 106, 245, 1);
          color: white;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          cursor: pointer;
          transition: 200ms ease;

          &:hover {
            background-color: rgb(4, 91, 206);
          }
        }
      }
    }
  }
}

.fade-enter-active {
  $modalOpen: True;

  @if $modalOpen ==True {
    .modal {
      transform: scale(0);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transform: scale(1);
  transition: opacity 500ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.permission-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.permission-button:hover {
  background-color: #0056b3;
}
</style>

<template>
    <div class="card">
        <div class="card-header">
            <h3>Specific Question Analysis</h3>
        </div>
        <div class="chart-container">
            <div v-if="dataFetched" ref="ConditionAnalyticsChart" style="width: 100%; height: 400px;"></div>
            <p v-else class="empty-message">
                <i class="fa fa-exclamation-triangle "></i> Please select a tool, section, and question to see the
                analysis
            </p>
        </div>
        <div class="dropdown-container">

            <div class="container">
                <select v-model="selectedTool" @change="resetData">
                    <option disabled value="">Select Tool</option>
                    <option value="1" v-if="hasPermission('tools', 'patient_tool')">Patient Tool</option>
                    <option value="2" v-if="hasPermission('tools', 'health_facility_tool')">Health Facility</option>
                    <option value="3" v-if="hasPermission('tools', 'community_based_tool')">Community Based</option>
                    <option value="4" v-if="hasPermission('tools', 'observation_tool')">Observation Tool</option>
                </select>


                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>

            <div class="container">

                <select v-model="selectedCondition" @change="resetData">
                    <option disabled value="">Select Section </option>
                    <option value="0">Introductory Questions</option>
                    <option value="1">HIV Questions</option>
                    <option value="2">Malaria Questions</option>
                    <option value="3">TB Questions</option>
                    <option value="4">KVP Questions</option>
                </select>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>

            <div class="container">
                <select v-model="selectedQuestion" @change="fetchChartData">
                    <option disabled value="">Select Question</option>
                    <option v-if="selectedCondition == 0" value="111">How long did it take you to travel from the
                        village to
                        the health facility?</option>
                    <option v-if="selectedCondition == 0" value="113">Was it convenient for you to reach the HF?
                    </option>
                    <option v-if="selectedCondition == 0" value="115">When you arrived at the Health Facility, how long
                        did
                        it take you to access services?</option>
                    <option v-if="selectedCondition == 1" value="479">Were any of the HIV IECs</option>
                    <option v-if="selectedCondition == 1" value="483">Did the health facility provide you with privacy
                        and
                        confidentiality you need when accessing HIV services?</option>
                    <option v-if="selectedCondition == 1" value="485">After testing HIV positive, were you asked to test
                        for
                        TB?</option>
                    <option v-if="selectedCondition == 1" value="491">Are you able to access your ART from the health
                        facility?</option>
                    <option v-if="selectedCondition == 1" value="497">In the last 2 years, how often have you received
                        counseling or support related to ART adherence? Tick appropriately</option>
                    <option v-if="selectedCondition == 1" value="498">Have you encountered any stock outs or shortages
                        of
                        ART/ARVs medications at the health facility?</option>
                    <option v-if="selectedCondition == 1" value="499">Have you ever experienced any form of stigma and
                        or
                        discrimination at the health facility because you are a PLHIV?</option>
                    <option v-if="selectedCondition == 1" value="504">Did you have to pay for any HIV related prevention
                        and
                        treatment services that you received?</option>
                    <option v-if="selectedCondition == 4" value="510">In your opinion, are the health facility workers
                        knowledgeable on HIV related key and vulnerable groups and their service needs?</option>
                    <option v-if="selectedCondition == 4" value="521">In your opinion, as a KP, do you receive stigma
                        and
                        discrimination free services from healthcare workers at this facility?</option>
                    <option v-if="selectedCondition == 3" value="532">If YES, where was your screening for TB undertaken
                    </option>
                    <option v-if="selectedCondition == 3" value="533">If YES, where did you get tested? (select all that
                        apply):</option>
                    <option v-if="selectedCondition == 3" value="535">For how long did you have the TB symptoms before
                        you
                        were confirmed as having TB?</option>
                    <option v-if="selectedCondition == 3" value="536">After testing positive for TB, how long did it
                        take
                        you before you started treatment?</option>
                    <option v-if="selectedCondition == 3" value="538">After testing positive for TB, did the health
                        facility
                        seek to contact your immediate family members for TB screening and or testing?</option>
                    <option v-if="selectedCondition == 3" value="540">After testing positive for TB, were you advised
                        and
                        counseled to test for HIV?</option>
                    <option v-if="selectedCondition == 3" value="547">Did you have to pay for any TB related prevention
                        and
                        treatment services?</option>
                    <option v-if="selectedCondition == 3" value="550">Have you ever experienced any form of stigma and
                        or
                        discrimination at the health facility because having TB?</option>
                    <option v-if="selectedCondition == 2" value="565">Did you have to pay for any malaria related
                        prevention
                        and treatment services?</option>
                    <option v-if="selectedCondition == 2" value="566">If yes to Q107, did you pay through your pocket or
                        through the health insurance?</option>
                    <option v-if="selectedCondition == 2" value="568">Some malaria prevention and treatment services are
                        offered at village level by the CHWs. Can you tell us why you came to seek the services at the
                        health facility? Tick all that apply</option>
                    <option v-if="selectedCondition == 2" value="570">If NO, please explain why you did not get
                        treatment
                        for malaria? Tick all that apply</option>
                </select>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>


            </div>

            <div class="container">
                <select v-model="selectedProvince" @change="fetchDistricts">
                    <option disabled value="">Select Province</option>
                    <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                    </option>
                </select>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>


            </div>

            <div class="container">
                <select v-model="selectedDistrict" @change="fetchChartData">
                    <option disabled value="">Select District</option>
                    <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
                    </option>
                </select>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8001 9.60002L12.0001 14.4L7.20011 9.60002" stroke="#192434" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import * as echarts from "echarts";
import { nextTick } from 'vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            provinces: [],
            districts: [],
            selectedTool: '1',
            selectedCondition: '1',
            selectedQuestion: '497',
            chart: null,
            dataFetched: false,
            data: null,
            selectedProvince: "",
            selectedDistrict: "",
        };
    },
    computed: {
        ...mapGetters({
            hasPermission: 'hasPermission'
        }),
    },
    methods: {
        resetData() {
            this.dataFetched = false;
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
        },
        fetchChartData() {
            const endpoint = '/question-participant-count';

            const params = {
                tool_id: this.selectedTool || '1',
                condition_id: this.selectedCondition || '1',
                question_id: this.selectedQuestion || '497',
                province_id: this.selectedProvince || '',
                district_id: this.selectedDistrict || ''
            };

            axios.get(endpoint, { params })
                .then(response => {
                    if (response.data.status) {
                        this.data = response.data.data;
                        this.dataFetched = true;
                        nextTick(() => {
                            this.initChart();
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },
        initChart() {
            // Guard clause to ensure `data` and `responses` are defined
            if (!this.data || !this.data.responses) {
                console.warn('No data available to render the chart.');
                return;
            }

            if (!this.chart) {
                this.chart = echarts.init(this.$refs.ConditionAnalyticsChart);
            }

            const responses = this.data.responses || [];
            const colors = ['#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE', '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC'];

            // Basic chart configuration for debugging
            const option = {
                title: {
                    text: 'Response Analysis',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}'
                },
                xAxis: {
                    type: 'category',
                    data: responses.map(res => res.response || 'Unknown'), // Default to 'Unknown' if response is missing
                    axisLine: { lineStyle: { color: '#888' } }
                },
                yAxis: {
                    type: 'value',
                    axisLine: { lineStyle: { color: '#888' } },
                    splitLine: { show: false }
                },
                series: [{
                    type: 'bar', // Ensure 'type' is correctly defined
                    data: responses.map((res, index) => ({
                        value: res.participant_count || 0, // Default to 0 if participant_count is missing
                        itemStyle: {
                            color: colors[index % colors.length],
                            borderRadius: [10, 10, 0, 0]
                        }
                    })),
                    barWidth: '60%'
                }]
            };

            // Set the option with the simplified configuration
            this.chart.setOption(option);
        },
        async fetchProvinces() {
            try {
                const response = await axios.get('/province');
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts() {
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },
        async fetchProvinces() {
            try {
                const response = await axios.get('/province');
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts() {
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        }
    },
    mounted() {
        this.fetchProvinces();
        this.fetchChartData();
        window.addEventListener('resize', () => {
            if (this.chart) this.chart.resize();
        });
    },
    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
    }
};
</script>


<style scoped>
.card {
    width: 100%;
    height: auto;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
}

.card-header {
    margin-bottom: 20px;

    h3 {
        font-weight: 400;
    }

}

.chart-container {
    width: 80%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
}

.empty-message {
    transform: translate(-50%, -50%);
    color: #888;
    font-size: 18px;
    text-align: center;
    margin-left: 50%;
}


.dropdown-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
}


select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}


.container {
    position: relative;
    width: 100%;



    svg {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

select {

    height: 40px;
    padding-left: 15px;
    font-size: 16px;
    border: 1.9px solid lightgray;
    border-radius: 4px;
    color: #39325a;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-color: transparent;
    padding-right: 35px;

}
</style>
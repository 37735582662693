<template>
    <div class="card">
        <div class="card-header">
            <h3>Disability Distribution Analysis</h3>
            <button style="margin-left: 58rem" @click="copyChart" class="copy-button">
                <i class="fa fa-copy"></i> Copy
            </button>
        </div>
        <div class="custom-legend">
            <span v-for="(label, index) in chartData.labels" :key="index" class="legend-item"
                @click="toggleLegend(index)">
                <span class="legend-color" :style="{
                    backgroundColor: legendState[index] ? dynamicColors[index % dynamicColors.length] : '#d3d3d3',
                }"></span>
                {{ label }}
            </span>
        </div>
        <div class="chart-container">
            <div id="CasesChart" style="width: 100%; height: 100%;"></div>
        </div>

        <div class="dropdown-container">
            <!-- Tool Selection -->
            <select v-model="selectedTool" @change="fetchChartData">
                <option disabled value="">Select Tool</option>
                <option value="5" v-if="hasPermission('tools', 'service_user_survey')">Service User</option>
                <option value="6" v-if="hasPermission('tools', 'facility_manager_survey')">Facility Manager Survey
                </option>
                <option value="7" v-if="hasPermission('tools', 'ayp_survey')">AYP Survey</option>
                <option value="8" v-if="hasPermission('tools', 'kp_survey')">KP Survey</option>
                <option value="9" v-if="hasPermission('tools', 'observation_survey')">Observation Survey</option>
            </select>

            <!-- Province Selection -->
            <select v-model="selectedProvince" @change="fetchDistricts">
                <option disabled value="">Select Province</option>
                <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                </option>
            </select>

            <!-- District Selection -->
            <select v-model="selectedDistrict" @change="fetchSites">
                <option disabled value="">Select District</option>
                <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
                </option>
            </select>

            <!-- Site Selection -->
            <select v-model="selectedSite" @change="fetchChartData">
                <option disabled value="">Select Site</option>
                <option v-for="site in sites" :key="site.id" :value="site.id">{{ site.health_center_name }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "@/helpers/axios.js";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            provinces: [],
            districts: [],
            sites: [],
            selectedTool: "5",
            selectedProvince: "",
            selectedDistrict: "",
            selectedSite: "",
            chart: null,
            dynamicColors: [
                "#5470C6", "#91CC75", "#FAC858", "#EE6666", "#73C0DE", "#3BA272",
                "#FC8452", "#9A60B4", "#EA7CCC", "#8D93AB",
            ],
            chartData: {
                labels: [],
                values: [],
            },
            legendState: {},
        };
    },
    mounted() {
        this.fetchProvinces();
        this.initChart();
        this.fetchChartData();
        window.addEventListener("resize", this.resizeChart);
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chart) this.chart.dispose();
    },
    computed: {
        ...mapGetters({
            selectedLanguage: "selectedLanguage",
            hasPermission: 'hasPermission',
        }),

        questionId() {
            return this.selectedTool === '5' ? 2281 : this.selectedTool === '7' ? 2661 : this.selectedTool === '8' ? 2752 : null;
        },
    },
    watch: {
        selectedLanguage(newValue, oldValue) {
            this.fetchChartData();
        },
    },
    methods: {
        toggleLegend(index) {
            this.legendState[index] = !this.legendState[index];

            const filteredValues = this.chartData.values.map((value, idx) =>
                this.legendState[idx] ? value : 0
            );

            this.updateChart(filteredValues);
        },
        async fetchProvinces() {
            try {
                const response = await axios.get("/province/1");
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts() {
            if (!this.selectedProvince) return;
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
                this.fetchChartData(); // Fetch chart data when province changes
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },
        async fetchSites() {
            if (!this.selectedDistrict) return;
            try {
                const response = await axios.get(`/health-centers?district_id=${this.selectedDistrict}`);
                this.sites = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching sites:", error);
            }
        },
        async fetchChartData() {
            try {
                const payload = {
                    tool_id: this.selectedTool,
                    question_id: this.questionId,
                    province_id: this.selectedProvince,
                    district_id: this.selectedDistrict,
                    site_id: this.selectedSite,
                };
                const response = await axios.post("/answers/responses", payload);
                if (response.data.status) {
                    if (this.selectedLanguage === 1) {
                        const data = response.data.data;
                        this.chartData.labels = data.map(item => item.eng_response);
                        this.chartData.values = data.map(item => item.count);

                        this.legendState = this.chartData.labels.reduce((acc, _, index) => {
                            acc[index] = true;
                            return acc;
                        }, {});

                        this.updateChart(this.chartData.values);
                    }
                    else {
                        const data = response.data.data;
                        this.chartData.labels = data.map(item => item.response);
                        this.chartData.values = data.map(item => item.count);

                        this.legendState = this.chartData.labels.reduce((acc, _, index) => {
                            acc[index] = true;
                            return acc;
                        }, {});

                        this.updateChart(this.chartData.values);
                    }

                } else {
                    console.error("Error fetching data:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        },

        prepareChartData(data) {
            return {
                labels: data.map((item) => item.response),
                values: data.map((item) => item.count),
            };
        },

        initChart() {
            const chartDom = document.getElementById("CasesChart");
            if (!chartDom) {
                console.error("Chart container not found");
                return;
            }

            this.chart = echarts.init(chartDom);
            chartDom.style.width = "100%";
            chartDom.style.height = "450px";

            const option = {
                tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
                grid: { top: "15%", left: "5%", right: "5%", bottom: "10%", containLabel: true },
                xAxis: { type: "category", axisTick: { show: false }, data: [] },
                yAxis: { type: "value", splitLine: { lineStyle: { type: "dashed" } } },
                series: [{ type: "bar", data: [], barWidth: "40%" }],
            };

            this.chart.setOption(option);
        },

        updateChart(filteredValues) {
            const option = {
                tooltip: {
                    trigger: "item",
                    formatter: params => `${params.name}: ${params.value}`,
                },
                grid: {
                    top: "20%",
                    left: "5%",
                    right: "5%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: this.chartData.labels,
                    axisLabel: {
                        fontSize: 12,
                        color: "#666",
                    },
                    axisTick: { show: false },
                    axisLine: { lineStyle: { color: "#ddd" } },
                },
                yAxis: {
                    type: "value",
                    splitLine: { lineStyle: { type: "dashed" } },
                    axisLabel: {
                        fontSize: 12,
                        color: "#666",
                    },
                },
                series: [
                    {
                        type: "bar",
                        data: filteredValues.map((value, index) => ({
                            value,
                            itemStyle: {
                                color: this.dynamicColors[index % this.dynamicColors.length],
                                borderRadius: [8, 8, 0, 0],
                            },
                        })),
                        barWidth: "40%",
                        label: {
                            show: true,
                            position: "top",
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                            formatter: "{c}",
                        },
                    },
                ],
            };

            if (this.chart) {
                this.chart.setOption(option);
            }
        },

        resizeChart() {
            if (this.chart) this.chart.resize();
        },
        async copyChart() {
            if (this.chartInstance) {
                try {
                    const imageBase64 = this.chartInstance.getDataURL({
                        type: 'png',
                        backgroundColor: '#fff',
                        pixelRatio: 2,
                    });

                    const img = new Image();
                    img.src = imageBase64;

                    // Copy to clipboard
                    const blob = await fetch(imageBase64).then((res) => res.blob());
                    const clipboardItem = new ClipboardItem({ 'image/png': blob });
                    await navigator.clipboard.write([clipboardItem]);

                    Toast.success('Chart copied to clipboard!');
                } catch (error) {
                    console.error('Failed to copy chart:', error);
                    Toast.error('Failed to copy chart.');
                }
            }
        },
    },
};
</script>



<style scoped>
.card {
    width: 100%;
    height: 660px;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 15px;

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        margin-top: -35px;

        h3 {
            margin: 0;
        }

        .copy-button {
            padding: 8px 12px;
            background-color: #454545;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #555555;
            }
        }
    }

    .chart-container {
        width: 100%;
        height: 450px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown-container {
        display: flex;
        justify-content: space-around;
        gap: 15px;
        margin-top: 20px;
        width: 60%;

        select {
            padding: 10px;
            width: 22%;
            border: 1px solid #ccc;
            border-radius: 5px;
            background-color: #f9f9f9;
            color: #333;
            outline: none;

            &:focus {
                border-color: #5470c6;
            }
        }
    }
}

#CasesChart {
    width: 100%;
    height: 100%;
}

.custom-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-size: 14px;
    color: #666;
    cursor: pointer;
}

.legend-color {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 4px;
}
</style>
<template>
  <div class="users-container">
    <div class="users-header">
      <ul>
        <!-- See Users Tab -->
        <router-link :to="{ name: 'community_members' }"
          :class="{ 'active-user-link': $route.path.includes('/users/list') }">
          <li><strong>See Users</strong></li>
        </router-link>

        <!-- Create Accounts Tab -->
        <router-link v-if="user.user_type !== 'super_admin'" :to="{ name: 'health_facility_team_form' }"
          :class="{ 'active-user-link': $route.path.includes('users/accounts') }">
          <li><strong>Create Accounts</strong></li>
        </router-link>

        <!-- Deactivate Accounts Tab -->
        <router-link v-if="hasPermission('other', 'create_admin')" :to="{ name: 'deactivated_accounts' }"
          :class="{ 'active-user-link': $route.path.includes('users/deactivated') }">
          <li><strong>Deactivate Accounts</strong></li>
        </router-link>
      </ul>
    </div>

    <!-- Body -->
    <div class="users-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters({
      hasPermission: 'hasPermission',
    }),
  },
};
</script>


<style lang="scss" scoped>
.users-container {
  height: 100%;
  padding: 0px 3%;
  display: flex;
  flex-direction: column;

  .users-header {

    // background-color: red;

    // position: sticky;
    // top: 0px;
    ul {
      display: flex;
      list-style-type: none;
      gap: 20px;

      a {
        text-decoration: none;
        color: rgb(182, 182, 182);
        transition: 300ms ease;



        li {
          padding: 12px 16px;
        }
      }

      .active-user-link {

        color: #0A2472;
        border-radius: 8px;
        background-color: rgba(33, 72, 192, 10%)
      }
    }
  }
}
</style>

// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
import { createStore } from 'vuex';
import axios from "@/helpers/axios.js";
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
    user: null,
    permissions: {
      tools: [],
      conditions: [],
      other: [],
      all: []
    },
    token: null,
    projects: [], // Store projects list
    selectedProjectId: null, // Track active project_id
    selectedLanguage: 1,
  },
  getters: {
    hasPermission: (state) => (category, permission) => {
      if (state.permissions.all.includes('all')) {
        return true;
      }
      if (state.permissions[category]) {
        return state.permissions[category].includes(permission);
      }
      return false;
    },
    selectedProjectId: (state) => state.selectedProjectId,
    projects: (state) => state.projects,
    selectedLanguage: (state) => state.selectedLanguage,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      console.log('User Set:', user);
      if (user && user.user_type !== 'super_admin') {
        state.selectedProjectId = user.project_id;
        console.log('Project ID Set:', state.selectedProjectId);
      }
    },
    setPermissions(state, permissions) {
      state.permissions = {
        tools: permissions.tools || [],
        conditions: permissions.conditions || [],
        other: permissions.other || [],
        all: permissions.all || []
      };
    },
    setToken(state, token) {
      state.token = token;
    },
    setProjects(state, projects) {
      state.projects = Array.isArray(projects) ? projects : [];
      if (!state.selectedProjectId && state.projects.length > 0) {
        state.selectedProjectId = state.projects[0].id;
      }
    },
    setSelectedLanguage(state, language) {
      state.selectedLanguage = language;
    },

    setSelectedProjectId(state, projectId) {
      state.selectedProjectId = projectId;
    },
    logout(state) {
      state.user = null;
      state.permissions = { tools: [], conditions: [], other: [], all: [] };
      state.token = null;
      state.projects = [];
      state.selectedProjectId = null;
      localStorage.clear();
    },
  },
  actions: {
    async loginUser({ commit }, credentials) {
      try {
        const response = await axios.post('admin/login', credentials);
        const { user, permissions, token } = response.data;

        // Vuex updates
        commit('setUser', user);
        commit('setPermissions', permissions);
        commit('setToken', token);

        // Update localStorage
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('token', token);

        // Set axios default header
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        console.log('Login Success:', response.data);

        return response.data;
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    async fetchProjects({ commit, state }) {
      if (state.user?.user_type !== 'super_admin') return; // Skip for non-super_admin
      try {
        const response = await axios.get('/projects');
        if (response.data.status) {
          commit('setProjects', response.data.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
    setSelectedProject({ commit }, projectId) {
      commit('setSelectedProjectId', projectId);
    },
    setSelectedLanguage({ commit }, language) {
      commit('setSelectedLanguage', language);
    },
    loadUserFromLocalStorage({ commit }) {
      const user = JSON.parse(localStorage.getItem('user'));
      const permissions = JSON.parse(localStorage.getItem('permissions'));
      const token = localStorage.getItem('token');

      if (user && permissions && token) {
        commit('setUser', user);
        commit('setPermissions', permissions);
        commit('setToken', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    },
  },
  plugins: [createPersistedState()]
});

export default store;










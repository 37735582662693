<template>
  <div class="page-container">
    <div class="teams-header">
      <ul>
        <li v-for="team in teams" :key="team.id" :class="{ active: selectedTeamId === team.id }"
          @click="selectTeam(team.id)">
          {{ team.name }}
        </li>
        <li>
          <button @click="showDownloadModal = true" class="download-data-btn"> <i class="fa fa-download"></i> Download
            Data</button>
        </li>
      </ul>
    </div>

    <div class="tools-container">
      <div class="tools-header">
        <ul>
          <li v-for="tool in tools" :key="tool.id" :class="{ active: selectedToolId === tool.id }"
            @click="selectTool(tool.id)">
            {{ tool.name }}
          </li>
        </ul>
      </div>
      <div class="filters-section">
        <input type="text" v-model="searchHealthCenter" placeholder="Search by Sites" @input="fetchFilteredData" />
        <input type="date" v-model="fromDate" @change="fetchFilteredData" />
        <input type="date" v-model="toDate" @change="fetchFilteredData" />

        <div class="dropdown">
          <button class="dropdown-toggle" @click="toggleDropdown">
            <i class="fa fa-download"></i> Export Options
          </button>
          <div v-if="downloading" class="downloading-message">
            Downloading...
          </div>
          <div v-else>
            <div v-if="showDropdown" class="dropdown-menu">
              <button @click="exportReport('all')" class="dropdown-item">
                Export All
              </button>&nbsp;&nbsp;
              <button @click="exportReport('tool')" class="dropdown-item">
                Export Tool
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="report-table" v-if="reportData.length > 0">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Date</th>
            <th>Surveyor</th>
            <th>Interviewee</th>
            <th>District</th>
            <th>Site</th>
            <th>Questionnaire</th>
            <th>Urgency</th>
            <th>Comment</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="item.id">
            <td>{{ calculateIndex(index) }}</td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>{{ formatMemberName(item.surveyor) }}</td>
            <td>{{ item.participant_code }}</td>
            <td>{{ item.district || "N/A" }}</td>
            <td>{{ item.health_center_name || "N/A" }}</td>
            <td><a href="#" @click.prevent="viewSurvey(item)">View Survey</a></td>
            <td :class="getUrgencyClass(item.rating)">{{ item.rating }}</td>
            <td><a href="#" @click.prevent="viewComment(item)">Comment</a></td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination-container">
      <button @click="prevPage" :disabled="currentPage === 1">&laquo;</button>
      <span v-for="page in totalPages" :key="page" @click="gotoPage(page)" :class="{ active: currentPage === page }">
        {{ page }}
      </span>
      <button @click="nextPage" :disabled="currentPage === totalPages">&raquo;</button>
    </div>
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <div class="modal-header">
          <button class="close-btn" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <div v-if="comments.length > 0">
            <div v-for="(comment, index) in comments" :key="index" class="comment-item">
              <p>{{ comment.interviewee_comment }}</p>
              <!-- Loop through images and display them -->
              <div v-if="comment.images && comment.images.length > 0" class="image-gallery">
                <img v-for="(image, imgIndex) in comment.images" :key="imgIndex" :src="image.image_url"
                  alt="Comment Image" class="comment-image" />
              </div>
            </div>
          </div>
          <div v-else>
            <p>No comments available.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDownloadModal" class="modal-overlay" @click="closeDownloadModal">
      <div class="modal" @click.stop>
        <div class="modal-header">
          <button class="close-btn" @click="closeDownloadModal">X</button>
        </div>
        <div class="modal-body">
          <h3 class="title">Customize Download</h3>
          <!-- Language Selection -->
          <!-- <div class="card">
            <h4>Select Language</h4>
            <div class="options">
              <label>
                <input type="radio" v-model="selectedLanguage" value="English" /> English
              </label>
              <label>
                <input type="radio" v-model="selectedLanguage" value="Kinyarwanda" /> Kinyarwanda
              </label>
            </div>
          </div> -->

          <!-- File Options -->
          <div class="card">
            <h4>Choose File Options</h4>
            <div class="options">
              <label>
                <input type="checkbox" v-model="fileOptions.locationBase" /> Location Base
              </label>
              <label>
                <input type="checkbox" v-model="fileOptions.siteBase" /> Site Base
              </label>
            </div>
          </div>
          <div class="card">
            <h4>Date Range</h4>
            <div>
              <label>
                <input type="radio" v-model="dateFilterOption" value="all" /> All Dates
              </label>
              <label>
                <input type="radio" v-model="dateFilterOption" value="range" /> Specific Dates
              </label>
            </div>
            <div v-if="dateFilterOption === 'range'" class="date-range">
              <label>
                From:
                <input type="date" v-model="fromDate" />
              </label>
              <label>
                To:
                <input type="date" v-model="toDate" />
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button :disabled="downloading" @click="downloadFile"><i class="fa fa-download"></i>
            {{ downloading ? "Downloading ..." : "Download File" }}
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { mapState } from "vuex";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      teams: [],
      tools: [],
      reportData: [],
      selectedTeamId: null,
      selectedToolId: null,
      loading: false,
      currentPage: 1,
      itemsPerPage: 20,
      showModal: false,
      comments: [],
      searchHealthCenter: "",
      fromDate: "",
      toDate: "",
      showDownloadModal: false,
      // selectedLanguage: "English",
      fileOptions: {
        locationBase: false,
        siteBase: false,
      },
      dateFilterOption: "all",
      fromDate: "",
      toDate: "",
      downloading: false,
      showDropdown: false,
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.selectedProjectId,
      selectedLanguage: 'selectedLanguage',
    }),
    totalPages() {
      return Math.ceil(this.reportData.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.reportData.slice(start, start + this.itemsPerPage);
    },
  },
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.resetState();
        this.fetchTeams();
      },
    },
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async fetchTeams() {
      this.loading = true;
      try {
        const response = await axios.get("/teams", { params: { project_id: this.projectId } });
        this.teams = response.data.data || [];
        if (this.teams.length > 0) this.selectTeam(this.teams[0].id);
      } catch (error) {
        console.error("Error fetching teams:", error);
      } finally {
        this.loading = false;
      }
    },
    resetState() {
      this.teams = [];
      this.tools = [];
      this.reportData = [];
      this.selectedTeamId = null;
      this.selectedToolId = null;
      this.currentPage = 1;
      this.searchHealthCenter = "";
      this.fromDate = "";
      this.toDate = "";
    },

    async selectTeam(teamId) {
      this.selectedTeamId = teamId;
      this.tools = [];
      this.selectedToolId = null;
      this.reportData = [];
      try {
        const response = await axios.get("/all/tools", { params: { team_id: teamId } });
        this.tools = response.data.data || [];
        if (this.tools.length > 0) this.selectTool(this.tools[0].id);
      } catch (error) {
        console.error("Error fetching tools:", error);
      }
    },
    async selectTool(toolId) {
      this.selectedToolId = toolId;
      this.loading = true;
      this.reportData = [];
      this.currentPage = 1;
      this.searchHealthCenter = "";
      this.fromDate = "";
      this.toDate = "";
      try {
        const response = await axios.get(`/admin/survey/${toolId}`);
        this.reportData = response.data.data || [];
      } catch (error) {
        console.error("Error fetching report data:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchFilteredData() {
      try {
        const response = await axios.get(`/admin/survey/${this.selectedToolId}`, {
          params: {
            health_center_name: this.searchHealthCenter,
            from_date: this.fromDate,
            to_date: this.toDate,
          },
        });
        this.reportData = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    getToolName(toolId) {
      const tool = this.tools.find((tool) => tool.id === toolId);
      console.log("test", tool.name);
      return tool ? tool.name : "N/A";
    },
    getSelectedToolName() {
      if (!this.selectedToolId) {
        console.error("No tool is currently selected.");
        return "N/A";
      }
      const selectedTool = this.tools.find((tool) => tool.id === this.selectedToolId);
      return selectedTool ? selectedTool.name : "N/A";
    },

    async exportReport(type) {
      this.downloading = true;
      try {
        let exportData = [];
        let sheetName = "";

        if (type === "all") {
          for (const tool of this.tools) {
            const response = await axios.get(`/admin/survey/${tool.id}`);
            const toolData = response.data.data || [];
            const formattedToolData = toolData.map((item, index) => ({
              "Tool": tool.name,
              "Date": this.formatDate(item.created_at),
              "Surveyor": this.formatMemberName(item.surveyor),
              "Interviewee": item.participant_code,
              "District": item.district || "N/A",
              "Site": item.health_center_name || "N/A",
              "Urgency": item.rating,
              "Status": item.status,
            }));
            exportData = exportData.concat(formattedToolData);
          }
          sheetName = "All Tools";
        } else if (type === "tool") {
          const response = await axios.get(`/admin/survey/${this.selectedToolId}`);
          const toolName = this.getSelectedToolName();
          const toolData = response.data.data || [];
          exportData = toolData.map((item, index) => ({
            "No": index + 1,
            "Date": this.formatDate(item.created_at),
            "Surveyor": this.formatMemberName(item.surveyor),
            "Interviewee": item.participant_code,
            "District": item.district || "N/A",
            "Site": item.health_center_name || "N/A",
            "Urgency": item.rating,
            "Status": item.status,
          }));
          // Truncate or sanitize the tool name
          sheetName = toolName.length > 31 ? toolName.substring(0, 28) + "..." : toolName;
        }

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        const fileName = type === "all" ? "all_tools_report.xlsx" : `tool_report_${this.getSelectedToolName()}.xlsx`;
        XLSX.writeFile(workbook, fileName);
        this.downloading = false;
      } catch (error) {
        console.error("Error exporting data:", error);
        this.downloading = false;
      }
      finally {
        this.downloading = false;
      }
    },


    viewSurvey(item) {
      const userId = item.surveyor?.id;
      const sessionId = item.session_id;
      const participantId = item.participant_id;

      if (userId && sessionId && participantId) {
        this.$router.push(`/survey/${userId}/${sessionId}/${participantId}`);
      } else {
        console.error("Missing required parameters to view survey.");
      }
    },
    async viewComment(item) {
      try {
        const user_id = item.surveyor?.id || null;
        const session_id = item.session_id || null;
        const participant_id = item.participant_id || null;

        if (!user_id || !session_id || !participant_id) {
          console.error("Missing required identifiers for fetching comments.");
          return;
        }

        const response = await axios.post("/admin/get-interviewer-comments", {
          session_id,
          user_id,
          participant_id,
        });

        if (response.data.status && response.data.data.length > 0) {
          this.comments = response.data.data;
          // console.log("Comments:", this.comments);
          this.showModal = true;
        } else {
          console.warn("No comments found.");
          this.comments = [];
          this.showModal = true;
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },

    closeModal() {
      this.showModal = false;
      this.comments = [];
    },
    closeDownloadModal() {
      this.showDownloadModal = false;
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    formatMemberName(member) {
      return member ? `${member.first_name} ${member.last_name}` : "N/A";
    },
    calculateIndex(index) {
      return (this.currentPage - 1) * this.itemsPerPage + index + 1;
    },
    gotoPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    getUrgencyClass(rating) {
      return {
        high: "badge-high",
        medium: "badge-medium",
        low: "badge-low",
      }[rating] || "";
    },
    async downloadFile() {
      if (!this.fileOptions.locationBase && !this.fileOptions.siteBase) {
        alert("Please select at least one file option.");
        return;
      }

      this.downloading = true;

      try {
        const requests = [];

        if (this.fileOptions.locationBase) {
          requests.push(this.fetchAndDownloadData("location"));
        }
        if (this.fileOptions.siteBase) {
          requests.push(this.fetchAndDownloadData("site"));
        }

        await Promise.all(requests);
        this.showDownloadModal = false;
      } catch (error) {
        console.error("Error during file download:", error);
      } finally {
        this.downloading = false;
      }
    },

    async fetchAndDownloadData(type) {
      const endpoint = type === "location" ? "/location-based-data" : "/site-based-data";

      // Prepare request data
      const requestData = {
        project_id: this.projectId,
        language_id: 2,
      };

      // Include dates only if "Specific Dates" is selected
      if (this.dateFilterOption === "range") {
        if (!this.fromDate || !this.toDate) {
          alert("Please select both From and To dates.");
          return;
        }
        requestData.from_date = this.fromDate;
        requestData.to_date = this.toDate;
      }

      try {
        const response = await axios.post(endpoint, requestData);

        if (response.data && response.data.status) {
          if (type === "location") {
            this.generateMultiSheetExcel(response.data.data);
          } else if (type === "site") {
            const formattedData = this.formatSiteData(response.data.data);
            this.downloadExcel(formattedData, "Site-Based-Data.xlsx");
          }
        } else {
          console.error("API Error: ", response.data.message || "Unknown error");
        }
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      }
    },
    generateMultiSheetExcel(data) {
      const workbook = XLSX.utils.book_new();

      data.tools.forEach((tool) => {
        const toolData = this.formatLocationData(tool); // Format data for this tool
        const worksheet = XLSX.utils.json_to_sheet(toolData);

        this.styleHeaderRow(worksheet);

        XLSX.utils.book_append_sheet(workbook, worksheet, tool.tool_name);
      });

      // Write the workbook to a file
      XLSX.writeFile(workbook, "Location-Based-Data.xlsx");
    },

    formatLocationData(tool) {
      const formattedData = [];

      if (this.selectedLanguage === 1) {
        tool.conditions.forEach((condition) => {
          condition.questions.forEach((question) => {
            question.responses.forEach((response) => {
              response.districts.forEach((district) => {
                formattedData.push({
                  Condition: condition.condition_name,
                  Question: question.eng_question,
                  Response: response.eng_response,
                  Locations: `${district.district_name}`,
                  Count: district.response_count || 0,
                });
              });
            });
          });
        });

        return formattedData;
      }
      else {
        tool.conditions.forEach((condition) => {
          condition.questions.forEach((question) => {
            question.responses.forEach((response) => {
              response.districts.forEach((district) => {
                formattedData.push({
                  Condition: condition.condition_name,
                  Question: question.question,
                  Response: response.response,
                  Locations: `${district.district_name}`,
                  Count: district.response_count || 0,
                });
              });
            });
          });
        });

        return formattedData;
      }
    },

    formatSiteData(data) {
      const formattedData = [];

      data.districts.forEach((district) => {
        district.tools.forEach((tool) => {
          tool.conditions.forEach((condition) => {
            condition.questions.forEach((question) => {
              question.responses.forEach((response) => {
                response.sites.forEach((site) => {
                  // Each site will be a separate row with its count
                  if (this.selectedLanguage === 1) {
                    formattedData.push({
                      District: district.district_name,
                      Tool: tool.tool_name,
                      Condition: condition.condition_name,
                      Question: question.eng_question,
                      Response: response.eng_response,
                      Site: site.site_name,
                      Count: site.response_count || 0, // Default to 0 if count is missing
                    });
                  }
                  else {
                    formattedData.push({
                      District: district.district_name,
                      Tool: tool.kiny_tool_name,
                      Condition: condition.condition_name,
                      Question: question.question,
                      Response: response.response,
                      Site: site.site_name,
                      Count: site.response_count || 0, // Default to 0 if count is missing
                    });
                  }
                });
              });
            });
          });
        });
      });

      return formattedData;
    },

    downloadExcel(data, fileName) {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();

      // Style the header row
      this.styleHeaderRow(worksheet);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      XLSX.writeFile(workbook, fileName);
    },
    styleHeaderRow(worksheet) {
      const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
      const headerRow = headerRange.s.r; // First row (0-indexed)

      // Iterate over each column in the header row
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: headerRow, c: col });
        const cell = worksheet[cellAddress];

        if (cell) {
          // Apply bold font, border, and background color
          cell.s = {
            font: { bold: true }, // Bold font
            fill: { fgColor: { rgb: "D9EAD3" } }, // Light green background
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
            alignment: { horizontal: "center", vertical: "center" }, // Center alignment
          };
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .teams-header {
    ul {
      display: flex;
      gap: 15px;
      list-style: none;
      margin-bottom: 10px;

      li {
        cursor: pointer;
        padding: 10px 15px;
        color: #667085;
        font-weight: bold;
        transition: color 0.3s ease, border-bottom 0.3s ease;

        &.active {
          color: #0a2472;
          border-bottom: 3px solid #0a2472;
        }

        &:hover {
          color: #2148c0;
        }
      }
    }
  }

  .tools-container {
    background-color: #003d8c;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .tools-header ul {
      display: flex;
      gap: 15px;
      list-style: none;
      margin: 0;

      li {
        cursor: pointer;
        padding: 8px 16px;
        color: white;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &.active {
          background-color: white;
          color: #0a2472;
          font-weight: bold;
        }

        &:hover {
          background-color: #f6fcff;
          color: #0a2472;
        }
      }
    }

    .filters-section {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: flex-start;
      margin-top: 10px;
      width: 90%;

      input,
      button {
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid #ccc;
        outline: none;
      }

      input {
        flex: 1;
        min-width: 150px;
      }

      button {
        background-color: #fff;
        color: #0a2472;
        cursor: pointer;
        border: 1px solid #0a2472;
        font-weight: bold;

        &:hover {
          background-color: #0a2472;
          color: #fff;
        }
      }

      .dropdown {
        position: relative;

        .dropdown-toggle {
          padding: 8px 12px;
          border: 1px solid #ccc;
          background-color: #0a2472;
          color: white;
          border-radius: 5px;
          font-size: 14px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #2148c0;
          }
        }

        .dropdown-menu {
          position: absolute;
          top: 100%;
          left: 0;
          background-color: white;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 252px;
          padding: 15px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 1000;

          .dropdown-item {
            padding: 10px 15px;
            color: #333;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: #c9c9c9;
            }
          }
        }
      }
    }
  }

  .report-table {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background-color: #f9fafb;

        th {
          padding: 12px;
          color: #344054;
          text-align: center;
          font-size: 14px;
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #e4e7ec;

          &:hover {
            background-color: #f1f5f9;
          }

          td {
            padding: 10px;
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;

    button,
    span {
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;

      &.active {
        background-color: #0a2472;
        color: #fff;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .modal {
      background: white;
      border-radius: 8px;
      width: 500px;
      max-height: 90%;
      overflow-y: auto;
      padding: 20px;
      // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);

      .modal-header {
        display: flex;
        justify-content: flex-end;

        .close-btn {
          background-color: #e74c3c;
          color: white;
          border: none;
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 4px;
          font-size: 16px;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .comment-item {
          border-bottom: 1px solid #ddd;
          padding: 10px 0;

          p {
            margin: 0;
            font-size: 14px;
            color: #333;
          }

          .comment-image {
            margin-top: 10px;
            max-width: 100%;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .card {
        background: #f9f9f9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        h4 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #333;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 10px;

          label {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #555;

            input[type="radio"],
            input[type="checkbox"] {
              margin-right: 10px;
              width: 16px;
              height: 16px;
              cursor: pointer;
            }

            input[type="radio"]:checked+span,
            input[type="checkbox"]:checked+span {
              color: #0a2472;
              /* Highlight color */
              font-weight: bold;
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button {
          background: #0a2472;
          color: white;
          border: none;
          padding: 10px 15px;
          font-size: 14px;
          font-weight: bold;
          border-radius: 5px;
          cursor: pointer;
          transition: background 0.3s ease;

          &:hover {
            background: #2148c0;
          }
        }
      }
    }
  }

  .export-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #0a2472;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #2148c0;
    }

    .download-icon {
      width: 16px;
      height: 16px;
      fill: white;
    }
  }

}

.image-gallery {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.comment-image {
  width: 164px;
  // height: 120px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.title {
  text-align: center;
}

.download-data-btn {
  background-color: #003d8cde;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.download-data-btn:hover {
  background-color: #1d5db0de;
}

.date-range {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.date-range label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.date-range input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 150px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.date-range input[type="date"]:focus {
  border-color: #4f81bd;
  box-shadow: 0 0 4px rgba(79, 129, 189, 0.5);
  outline: none;
}

.date-range label span {
  font-size: 12px;
  color: #666;
}

.downloading-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: bold;
  color: #333;
  z-index: 1000;
}
</style>

<template>
    <div class="card">
        <div class="card-header">
            <h3>Gender-Based Distribution</h3>
            <button style="margin-left: 15rem" @click="copyChart" class="copy-button">
                <i class="fa fa-copy"></i> Copy
            </button>
        </div>
        <div class="content">
            <div class="chart-container" ref="chartContainer"></div>
        </div>

        <div class="dropdown-container">
            <div class="container">
                <!-- Tool Selection -->
                <select v-model="selectedTool" @change="handleToolChange">
                    <option disabled value="">Select Tool</option>
                    <option value="5" v-if="hasPermission('tools', 'service_user_survey')">Service User</option>
                    <option value="6" v-if="hasPermission('tools', 'facility_manager_survey')">Facility Manager
                        Survey</option>
                    <option value="7" v-if="hasPermission('tools', 'ayp_survey')">AYP Survey</option>
                    <option value="8" v-if="hasPermission('tools', 'kp_survey')">KP Survey</option>
                    <option value="9" v-if="hasPermission('tools', 'observation_survey')">Observation Survey
                    </option>
                </select>

                <!-- Province Selection -->
                <select v-model="selectedProvince" @change="fetchDistricts">
                    <option disabled value="">Select Province</option>
                    <option v-for="province in provinces" :key="province.id" :value="province.id">
                        {{ province.name }}
                    </option>
                </select>

                <!-- District Selection -->
                <select v-model="selectedDistrict" @change="fetchSites">
                    <option disabled value="">Select District</option>
                    <option v-for="district in districts" :key="district.id" :value="district.id">
                        {{ district.name }}
                    </option>
                </select><br>

                <!-- Site Selection -->
                <select v-model="selectedSite" @change="fetchChartData">
                    <option disabled value="">Select Site</option>
                    <option v-for="site in sites" :key="site.id" :value="site.id">
                        {{ site.health_center_name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            provinces: [],
            districts: [],
            sites: [],
            selectedTool: "5",
            selectedProvince: "",
            selectedDistrict: "",
            selectedSite: "",
            chartInstance: null,
            lastValidData: null
        };
    },
    mounted() {
        this.initChart();
        this.fetchProvinces();
        this.fetchChartData();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chartInstance) this.chartInstance.dispose();
    },
    computed: {
        ...mapGetters({
            selectedLanguage: 'selectedLanguage',
            hasPermission: 'hasPermission',
        }),

        questionId() {
            return this.selectedTool === '5' ? 2278 : this.selectedTool === '7' ? 2659 : null;
        },
    },
    watch: {
        selectedLanguage() {
            this.fetchChartData();
        },
    },
    methods: {
        handleToolChange() {
            console.log("Tool changed to: ", this.selectedTool);
            this.fetchChartData();
        },

        initChart() {
            this.chartInstance = echarts.init(this.$refs.chartContainer);
            const placeholderOption = {
                series: [
                    {
                        name: 'Responses',
                        type: 'pie',
                        radius: ['45%', '75%'],
                        data: [
                            { value: 1, name: 'Loading...', itemStyle: { color: 'rgba(200, 200, 200, 0.3)' } }
                        ],
                        label: { show: false },
                        labelLine: { show: false },
                    },
                ],
            };
            this.chartInstance.setOption(placeholderOption);
        },

        async fetchChartData() {
            console.log(this.selectedTool, this.questionId);
            try {
                const payload = {
                    tool_id: this.selectedTool,
                    question_id: this.questionId,
                    district_id: this.selectedDistrict,
                    site_id: this.selectedSite
                };
                const response = await axios.post('/answers/responses', payload);
                if (response.data.status) {
                    if (this.selectedLanguage === 1) {
                        const chartData = response.data.data.map(item => ({
                            value: item.count,
                            name: item.eng_response,
                        }));
                        this.lastValidData = chartData;
                        this.updateChart(chartData);
                    }
                    else {
                        const chartData = response.data.data.map(item => ({
                            value: item.count,
                            name: item.response,
                        }));
                        this.lastValidData = chartData;
                        this.updateChart(chartData);
                    }
                } else {
                    console.error("API call failed: ", response.data.message);
                    if (this.lastValidData) {
                        this.updateChart(this.lastValidData);
                    } else {
                        this.updateChart([]);
                    }
                }
            } catch (error) {
                console.error("Error fetching chart data: ", error);
                if (this.lastValidData) {
                    this.updateChart(this.lastValidData);
                } else {
                    this.updateChart([]);
                }
            }
        },


        async fetchProvinces() {
            try {
                const response = await axios.get('/province/1');
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },

        async fetchDistricts() {
            if (!this.selectedProvince) return;
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
                this.sites = []; // Clear sites when province changes
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },

        async fetchSites() {
            if (!this.selectedDistrict) return;
            try {
                const response = await axios.get(`/health-centers?district_id=${this.selectedDistrict}`);
                this.sites = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching sites:", error);
            }
        },

        updateChart(data) {
            const dynamicColors = ['#4B9CD3', '#FF69B4', '#A37DC7', '#A9A9A9'];

            const coloredData = data.map((item, index) => ({
                ...item,
                itemStyle: {
                    color: dynamicColors[index % dynamicColors.length],
                },
            }));

            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c} ({d}%)',
                },
                series: [
                    {
                        name: 'Gender-Based Distribution',
                        type: 'pie',
                        radius: ['42%', '72%'],
                        center: ['50%', '45%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: params => {
                                const maxLabelLength = 50;
                                const name =
                                    params.name.length > maxLabelLength
                                        ? params.name.substring(0, maxLabelLength) + '...'
                                        : params.name;
                                return `{name|${name}}\n{value|${params.value}} {percent|(${params.percent}%)} `;
                            },
                            fontSize: 12,
                            color: '#333',
                            rich: {
                                name: {
                                    fontSize: 14,
                                    color: '#333',
                                },
                                value: {
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000',
                                },
                                percent: {
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: '#000',
                                },
                            },
                        },
                        labelLine: {
                            show: true,
                            length: 15,
                            length2: 10,
                            smooth: true,
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 14,
                                fontWeight: 'bold',
                            },
                        },
                        data: coloredData,
                    },
                ],
            };

            if (this.chartInstance) {
                this.chartInstance.setOption(option);
            }
        },

        resizeChart() {
            if (this.chartInstance) {
                this.chartInstance.resize();
            }
        },
        async copyChart() {
            if (this.chartInstance) {
                try {
                    const imageBase64 = this.chartInstance.getDataURL({
                        type: 'png',
                        backgroundColor: '#fff',
                        pixelRatio: 2,
                    });

                    const img = new Image();
                    img.src = imageBase64;

                    // Copy to clipboard
                    const blob = await fetch(imageBase64).then((res) => res.blob());
                    const clipboardItem = new ClipboardItem({ 'image/png': blob });
                    await navigator.clipboard.write([clipboardItem]);

                    Toast.success('Chart copied to clipboard!');
                } catch (error) {
                    console.error('Failed to copy chart:', error);
                    Toast.error('Failed to copy chart.');
                }
            }
        },
    },
};
</script>



<style lang="scss" scoped>
.card {
    max-width: 50vw;
    height: 450px;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 24px;

    @media screen and (max-width: 1200px) {
        max-width: 100%;
    }

    @media screen and (max-width: 768px) {
        height: auto;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        margin-top: -35px;

        h3 {
            margin: 0;
            font-weight: 800;
            font-size: 20px;
        }

        .copy-button {
            padding: 8px 12px;
            background-color: #454545;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
            display: flex;
            align-items: center;

            &:hover {
                background-color: #555555;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80%;
        position: relative;
    }

    // .chart-container {
    //     width: 450px;
    //     height: 300px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    .chart-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: -20px;
        margin-bottom: -40px;
    }

    select {
        width: 200px;
        padding: 8px 12px;
        font-size: 14px;
        color: #39325a;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 8px;
        outline: none;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="gray" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 12px;

        &:hover {
            border-color: #5470c6;
        }

        &:focus {
            border-color: #5470c6;
            box-shadow: 0 0 5px rgba(84, 112, 198, 0.5);
        }
    }


}
</style>

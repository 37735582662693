<template>
    <div class="card">
        <div class="card-header">
            <h3>Service User-Based Stock Out Analysis</h3>
            <button style="margin-left: 52rem" @click="copyChart" class="copy-button">
                <i class="fa fa-copy"></i> Copy
            </button>
        </div>
        <div class="chart-container" ref="chartContainer"></div>
        <div class="dropdown-container">
            <div class="container">
                <select v-model="selectedTool" @change="fetchChartData">
                    <option disabled value="">Select Tool</option>
                    <option value="5" v-if="hasPermission('tools', 'service_user_survey')">Service User</option>
                    <option value="6" v-if="hasPermission('tools', 'facility_manager_survey')">Facility Manager Survey
                    </option>
                    <option value="7" v-if="hasPermission('tools', 'ayp_survey')">AYP Survey</option>
                    <option value="8" v-if="hasPermission('tools', 'kp_survey')">KP Survey</option>
                    <option value="9" v-if="hasPermission('tools', 'observation_survey')">Observation Survey</option>
                </select>
            </div>
            <div class="container">
                <select v-model="selectedProvince" @change="fetchDistricts">
                    <option disabled value="">Select Province</option>
                    <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                    </option>
                </select>
            </div>
            <div class="container">
                <select v-model="selectedDistrict" @change="fetchSites">
                    <option disabled value="">Select District</option>
                    <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
                    </option>
                </select>
            </div>
            <div class="container">
                <select v-model="selectedSite" @change="fetchChartData">
                    <option disabled value="">Select Site</option>
                    <option v-for="site in sites" :key="site.id" :value="site.id">{{ site.health_center_name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>


<script>
import * as echarts from 'echarts';
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            provinces: [],
            districts: [],
            sites: [],
            selectedTool: "5", // Default Tool ID
            selectedProvince: "",
            selectedDistrict: "",
            selectedSite: "",
            chartInstance: null,
        };
    },
    mounted() {
        this.fetchProvinces();
        this.initChart();
        this.fetchChartData();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chartInstance) this.chartInstance.dispose();
    },
    computed: {
        ...mapGetters({
            selectedLanguage: "selectedLanguage",
            hasPermission: "hasPermission",
        }),


        questionId() {
            return this.selectedTool === '5' ? 2305 : this.selectedTool === '7' ? 2683 : null;
        },
    },
    watch: {
        selectedLanguage(newValue, oldValue) {
            this.fetchChartData();
        },
    },
    methods: {
        initChart() {
            this.$refs.chartContainer.style.height = "500px";
            this.chartInstance = echarts.init(this.$refs.chartContainer);
        },
        async fetchProvinces() {
            try {
                const response = await axios.get("/province/1");
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts() {
            if (!this.selectedProvince) return;
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
                this.fetchChartData(); // Fetch chart data when district is selected
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },
        async fetchSites() {
            if (!this.selectedDistrict) return;
            try {
                const response = await axios.get(`/health-centers?district_id=${this.selectedDistrict}`);
                this.sites = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching sites:", error);
            }
        },
        async fetchChartData() {
            try {
                const payload = {
                    tool_id: this.selectedTool,
                    question_id: this.questionId, // Use dynamic question_id
                    province_id: this.selectedProvince,
                    district_id: this.selectedDistrict,
                    site_id: this.selectedSite,
                };
                const response = await axios.post("/answers/responses", payload);
                if (response.data.status) {
                    const chartData = this.prepareChartData(response.data.data);
                    this.updateChart(chartData);
                } else {
                    console.error("Error fetching data:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        },
        prepareChartData(data) {
            if (this.selectedLanguage === 1) {
                return {
                    labels: data.map(item => item.eng_response),
                    values: data.map(item => item.count),
                };
            }
            else {
                return {
                    labels: data.map(item => item.response),
                    values: data.map(item => item.count),
                };
            }
        },
        updateChart(chartData) {
            const total = chartData.values.reduce((sum, value) => sum + value, 0); // Calculate total

            const dynamicColors = [
                "#5470C6", // Soft Blue
                "#73C0DE", // Light Cyan
                "#91CC75", // Soft Green
                "#FAC858", // Yellow
                "#EE6666", // Light Red
                "#3BA272", // Teal Green
            ];

            const option = {
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b}: {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    left: "left",
                    textStyle: {
                        color: "#666",
                        fontSize: 13,
                        fontWeight: "bold",
                    },
                    formatter: (name) => {
                        // Find the index of the label
                        const index = chartData.labels.indexOf(name);
                        if (index >= 0) {
                            const value = chartData.values[index];
                            const percentage = ((value / total) * 100).toFixed(2);
                            return `${name}: ${value} (${percentage}%)`;
                        }
                        return name; // Fallback if not found
                    },
                },
                series: [
                    {
                        name: "Service User-Based Stock Out Analysis",
                        type: "pie",
                        radius: ["45%", "80%"],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "18",
                                fontWeight: "bold",
                                formatter: "{b}\n{d}%",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: chartData.labels.map((label, index) => ({
                            value: chartData.values[index],
                            name: label,
                            itemStyle: {
                                color: dynamicColors[index % dynamicColors.length],
                            },
                        })),
                    },
                ],
            };

            if (this.chartInstance) {
                this.chartInstance.setOption(option);
            }
        },
        resizeChart() {
            if (this.chartInstance) {
                this.chartInstance.resize();
            }
        },
        async copyChart() {
            if (this.chartInstance) {
                try {
                    const imageBase64 = this.chartInstance.getDataURL({
                        type: 'png',
                        backgroundColor: '#fff',
                        pixelRatio: 2,
                    });

                    const img = new Image();
                    img.src = imageBase64;

                    // Copy to clipboard
                    const blob = await fetch(imageBase64).then((res) => res.blob());
                    const clipboardItem = new ClipboardItem({ 'image/png': blob });
                    await navigator.clipboard.write([clipboardItem]);

                    Toast.success('Chart copied to clipboard!');
                } catch (error) {
                    console.error('Failed to copy chart:', error);
                    Toast.error('Failed to copy chart.');
                }
            }
        },
    },
};
</script>



<style scoped>
.card {
    max-width: 100%;
    margin: 0 auto;
    height: 600px;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 15px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-top: -10px;

    h3 {
        margin: 0;
        font-weight: 700;
        font-size: 20px;
    }

    .copy-button {
        padding: 8px 12px;
        background-color: #454545;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #555555;
        }
    }
}

.chart-container {
    width: 100%;
    height: 450px !important;
}

.dropdown-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.container {
    position: relative;
    flex: 1;
}

select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: transparent;
    color: #39325a;
}
</style>
<template>
  <div class="form-container">
    <p>You can choose to activate the session from here</p><br>
    <div class="btn-container">
      <button @click="confirmActivation" :disabled="isActive" class="activate">
        <div class="icon"><i class="fa fa-check"></i></div>
        Activate
      </button>
      <p v-if="isActive">This session is already activated.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from "@/helpers/axios.js";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const Toast = useToast();
    const isActive = ref(false);

    const getSessionIdFromUrl = () => {
      const url = window.location.href;
      const sessionId = url.split("/").pop();
      return sessionId;
    };

    const checkSessionStatus = async () => {
      const sessionId = getSessionIdFromUrl();
      try {
        const response = await axios.get(`/admin/session/status/${sessionId}`);
        if (response.data.status) {
          isActive.value = response.data.data.active === "0";
        } else {
          Toast.error(response.data.message);
        }
      } catch (error) {
        Toast.error("An error occurred while checking the session status.");
      }
    };

    const confirmActivation = () => {
      if (confirm("Are you sure you want to activate this session?")) {
        activateSession();
      }
    };

    const activateSession = async () => {
      const toolId = 7;
      const sessionId = getSessionIdFromUrl();
      try {
        const response = await axios.put(`/admin/session/activate/${toolId}/${sessionId}`);
        if (response.data.status) {
          isActive.value = true;
          Toast.success(response.data.message);
        } else {
          Toast.error(response.data.message);
        }
      } catch (error) {
        Toast.error("An error occurred while activating the session.");
      }
    };

    onMounted(() => {
      checkSessionStatus();
    });

    return {
      isActive,
      confirmActivation
    };
  }
};
</script>

<style scoped>
.form-container {
  padding: 20px;
}

.btn-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.activate {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.activate:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.icon {
  display: inline-block;
  margin-right: 5px;
}
</style>

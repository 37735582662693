<template>
  <div class="uploads-container">
    <!-- Tools Header -->
    <div class="uploads-header">
      <ul>
        <template v-if="projectId === 1">
          <router-link v-if="hasPermission('tools', 'patient_tool')" :to="{ name: 'patient_tool_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'patient_tool' }" @click="setActiveTool('patient_tool')">
            <li><strong>Patient Tool</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'health_facility_tool')"
            :to="{ name: 'health_facility_tool_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'health_facility_tool' }"
            @click="setActiveTool('health_facility_tool')">
            <li><strong>Health Facility Tool</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'community_based_tool')"
            :to="{ name: 'community_based_tool_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'community_based_tool' }"
            @click="setActiveTool('community_based_tool')">
            <li><strong>Community Based Tool</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'observation_tool')" :to="{ name: 'observation_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'observation_tool' }"
            @click="setActiveTool('observation_tool')">
            <li><strong>Observation Tool</strong></li>
          </router-link>
        </template>

        <template v-else-if="projectId === 2">
          <router-link v-if="hasPermission('tools', 'service_user_survey')" :to="{ name: 'service_user_survey_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'service_user_survey' }"
            @click="setActiveTool('service_user_survey')">
            <li><strong>Service User Survey</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'facility_manager_survey')" :to="{ name: 'facility_team_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'facility_team' }" @click="setActiveTool('facility_team')">
            <li><strong>Facility Manager Survey</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'ayp_survey')" :to="{ name: 'ayp_survey_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'ayp_survey_uploads_session' }" @click="setActiveTool('ayp_survey_uploads_session')">
            <li><strong>AYP Survey</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'kp_survey')" :to="{ name: 'kp_survey_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'kp_survey_uploads_session' }" @click="setActiveTool('kp_survey_uploads_session')">
            <li><strong>KP Survey</strong></li>
          </router-link>
          <router-link v-if="hasPermission('tools', 'observation_survey')" :to="{ name: 'observation_survey_uploads_session' }"
            :class="{ 'active-user-link': activeTool === 'observation_survey_uploads_session' }" @click="setActiveTool('observation_survey_uploads_session')">
            <li><strong>Observation Survey</strong></li>
          </router-link>
        </template>
      </ul>
    </div>

    <!-- Fallback Message -->
    <div v-if="!activeTool" class="no-tools-message">
      <p>Please pick a survey to see what this tab holds.</p>
    </div>

    <!-- Uploads Body -->
    <div v-else class="uploads-body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      activeTool: null, 
    };
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    ...mapState({
      projectId: (state) => state.selectedProjectId, // Dynamically fetch project ID
    }),
  },
  watch: {
    projectId: {
      immediate: true,
      handler(newProjectId) {
        this.setDefaultTool(newProjectId);
      },
    },
  },
  methods: {
    setDefaultTool(projectId) {
      // Try to select the default tool; otherwise, set activeTool to null
      if (projectId === 1 && this.hasPermission("tools", "patient_tool")) {
        this.activeTool = "patient_tool";
        this.$router.push({ name: "patient_tool_uploads_session" });
      } else if (projectId === 2) {
        this.activeTool = "service_user_survey";
        this.$router.push({ name: "service_user_survey_uploads_session" });
      } else {
        this.activeTool = null; 
      }
    },
    setActiveTool(toolName) {
      this.activeTool = toolName;
    },
  },
};
</script>

<style lang="scss" scoped>
.uploads-container {
  height: 100%;
  padding: 0px 3%;
  display: flex;
  flex-direction: column;

  .uploads-header {
    ul {
      display: flex;
      list-style-type: none;
      gap: 12px;

      a {
        text-decoration: none;
        border-bottom: 3px solid transparent;
        color: rgb(182, 182, 182);
        transition: 300ms ease;

        li {
          padding: 12px 16px;
        }

        &:hover {
          border-bottom: 3px solid;
        }
      }

      .active-user-link {
        border-bottom: 3px solid #2148c0;
        color: #0a2472;
      }
    }
  }

  .no-tools-message {
    padding: 20px;
    text-align: center;
    color: #888;
    font-size: 16px;
  }
}
</style>

<template>
    <div class="card-container">
        <div class="language-selection">
            <label for="language">Select Language:</label>
            <select v-model="selectedLanguage" @change="fetchIntroduction">
                <option value="1">English</option>
                <option value="2">Kinyarwanda</option>
            </select>
        </div>

        <div class="introduction-section bordered-box">
            <h4>Greeting</h4>
            <div class="content-wrapper">
                <p v-if="!isLoading" class="text">{{ introduction.greating }}</p>
                <div v-else class="spinner-container">
                    <div class="spinner"></div>
                </div>
            </div>
            <button @click="openEditModal('greating')" class="edit-button-action" :disabled="isLoading">
                Edit
            </button>
        </div>

        <div class="introduction-section bordered-box">
            <h4>Paragraph</h4>
            <div class="content-wrapper">
                <p v-if="!isLoading" class="text">{{ introduction.first_paragraph }}</p>
                <div v-else class="spinner-container">
                    <div class="spinner"></div>
                </div>
            </div>
            <button @click="openEditModal('first_paragraph')" class="edit-button-action" :disabled="isLoading">
                Edit
            </button>
        </div>

        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="cancelEdit">&times;</span>
                <h2>Edit </h2>
                <textarea v-model="editValue" rows="7"></textarea>
                <div class="modal-actions">
                    <button class="save" @click="saveChanges">Save</button>
                    <button class="cancel" @click="cancelEdit">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { useToast } from "vue-toastification";
import { mapGetters } from 'vuex';

const Toast = useToast();

export default {
    data() {
        return {
            introduction: {
                id: null,
                greating: '',
                first_paragraph: ''
            },
            showModal: false,
            editField: '',
            editValue: '',
            selectedLanguage: 1,
            isLoading: false
        };
    },
    computed: {
        ...mapGetters({
            hasPermission: 'hasPermission'
        }),
    },
    methods: {
        fetchIntroduction() {
            const toolId = 5;
            const languageId = this.selectedLanguage;
            this.isLoading = true;

            axios.get(`/introductions/tool/${toolId}/${languageId}`)
                .then(response => {
                    if (response.data.status) {
                        this.introduction = response.data.data;
                    } else {
                        Toast.error("Failed to fetch introduction.");
                    }
                })
                .catch(error => {
                    Toast.error("An error occurred while fetching the introduction.");
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        openEditModal(field) {
            this.editField = field;
            this.editValue = this.introduction[field];
            this.showModal = true;
        },
        saveChanges() {
            this.isLoading = true;
            const updatedData = {
                [this.editField]: this.editValue
            };

            axios.put(`/introductions/${this.introduction.id}`, updatedData)
                .then(response => {
                    if (response.data.status) {
                        this.introduction[this.editField] = this.editValue;
                        Toast.success(`Updated successfully.`);
                    } else {
                        Toast.error("Failed to update the introduction.");
                    }
                })
                .catch(error => {
                    Toast.error("An error occurred while updating the introduction.");
                })
                .finally(() => {
                    this.showModal = false;
                    this.isLoading = false;
                });
        },
        cancelEdit() {
            this.showModal = false;
        }
    },
    mounted() {
        this.fetchIntroduction();
    }
};
</script>


<style lang="scss" scoped>
.card-container {
    .language-selection {
        margin-bottom: 20px;

        label {
            font-size: 1rem;
            margin-right: 10px;
        }

        select {
            padding: 5px 10px;
            font-size: 1rem;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
    }

    .bordered-box {
        border: 2px solid #E4E7EC;
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .edit-button-action {
        display: inline-block;
        margin-top: 10px;
        padding: 7px 15px;
        background-color: #0056b3;
        border: #0055b3;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 0.8rem;
        transition: background-color 200ms ease, transform 100ms ease;

        &:hover {
            background-color: #1163c0;
            transform: translateY(-2px);
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    .content-wrapper {
        position: relative;
        min-height: 60px;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.text {
    margin-top: 14px;
    font-size: 14px;
    line-height: 22px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
        max-width: 600px;
        border-radius: 10px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

        textarea {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            resize: vertical;
            margin-top: 10px;
            font-size: 1rem;
            color: #333;
        }

        .modal-actions {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 20px;
        }
    }

    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;

        &:hover,
        &:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.save {
    padding: 7px 15px;
    background-color: #0056b3;
    border: #0055b3;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;

    &:hover {
        background-color: #3482d5;
    }
}

.cancel {
    padding: 7px 15px;
    background-color: #c13c32;
    border: #0055b3;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    &:hover {
        background-color: #ab4d47;
    }
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0055b3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

<template>
  <div class="page-container">
    <!-- Sidebar Section -->
    <div class="sidebar">
      <div class="sidebar-header">
        <h3>Teams</h3>
        <button class="create-team-btn" @click="showCreateModal = true">
          <span>+ Create Team</span>
        </button>
      </div><br>
      <ul>
        <li v-for="(team, index) in dynamicTeams" :key="team.id">
          <a href="#" :class="{ 'active-link': selectedTeam === team.id }" @click="setActiveTeam(team.id, team.name)">
            {{ team.name }}
          </a>
        </li>
        <li>
          <a href="#" :class="{ 'active-link': selectedTeam === 'admin' }" @click="setActiveTeam('admin')">
            Admins
          </a>
        </li>
      </ul>
    </div>

    <!-- Form Section -->
    <div class="form-container">
      <p>Please fill the inputs with <b>{{ activeTeamName }}</b> information</p>
      <form @submit.prevent="handleSubmit" autocomplete="off">
        <div class="row">
          <div class="col">
            <div class="input-container">
              <input type="text" v-model="formData.first_name" required />
              <label>First Name</label>
            </div>
          </div>
          <div class="col">
            <div class="input-container">
              <input type="text" v-model="formData.last_name" required />
              <label>Last Name</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="input-container">
              <input type="text" v-model="formData.phone" required />
              <label>Phone Number</label>
            </div>
          </div>
          <div class="col">
            <div class="input-container">
              <input type="text" v-model="formData.nid" />
              <label>National ID</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="input-container">
              <select v-model="formData.gender" required>
                <option value="" disabled>-- Choose Gender --</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label>Gender</label>
            </div>
          </div>
          <div class="col">
            <div class="input-container">
              <input type="password" v-model="formData.password" required />
              <label>Password</label>
            </div>
          </div>
        </div>

        <!-- Conditional Section Based on Team Type -->
        <div v-if="selectedTeam !== 'admin'">
          <div class="row">
            <div class="col">
              <div class="input-container">
                <select v-model="formData.province_id" @change="fetchDistricts" required>
                  <option value="" disabled>-- Choose Province --</option>
                  <option v-for="province in provinces" :key="province.id" :value="province.id">
                    {{ province.name }}
                  </option>
                </select>
                <label>Province</label>
              </div>
            </div>
            <div class="col">
              <div class="input-container">
                <select v-model="formData.district_id" required>
                  <option value="" disabled>-- Choose District --</option>
                  <option v-for="district in districts" :key="district.id" :value="district.id">
                    {{ district.name }}
                  </option>
                </select>
                <label>District</label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTeam === 'admin'" class="permissions-section">
          <h3>Permissions</h3>
          <div class="permissions-grid">
            <div v-for="group in Object.keys(permissionGroups)" :key="group" class="permission-group">
              <h4>{{ logAndCapitalize(group) }}</h4>
              <div v-for="permission in permissionGroups[group]" :key="permission.id">
                <label>
                  <input type="checkbox" v-model="formData.permissions[group]" :value="permission.id" />
                  {{ permission.description }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="row">
          <div class="col">
            <button type="submit">Create</button>
          </div>
        </div>
      </form>
    </div>

    <!-- Create Team Modal -->
    <div v-if="showCreateModal" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <h3>Create New Team</h3>
        <form @submit.prevent="createTeam">
          <div class="input-container">
            <label>Team Name</label>
            <input type="text" v-model="newTeamName" placeholder="Enter Team Name" required />
          </div>
          <div class="btn-container">
            <button type="submit">Create</button>
            <button type="button" @click="closeModal">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      dynamicTeams: [], // List of teams
      selectedTeam: null, // Active team ID
      activeTeamName: "Health Facility Team", // Default team name
      formData: {
        first_name: "",
        last_name: "",
        phone: "",
        gender: "",
        nid: "",
        password: "",
        province_id: "",
        district_id: "",
      },
      provinces: [],
      districts: [],
      showCreateModal: false,
      newTeamName: "",
      permissions: [],
      permissionGroups: {
        tools: [],
        conditions: [],
        locations: [],
        other: []
      },
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        gender: "",
        password: "",
        user_type: "admin",
        province_id: "",
        district_id: "",
        permissions: {
          tools: [],
          conditions: [],
          locations: [],
          other: []
        }
      },
    };
  },
  setup() {
    const toast = useToast(); // Access toast instance
    return { toast };
  },
  computed: {
    projectId() {
      return this.$store.state.selectedProjectId; // Get project_id from Vuex store
    },
  },
  mounted() {
    this.fetchTeams();
    this.fetchProvinces();
    this.fetchPermissions();
  },
  methods: {
    async fetchPermissions() {
      try {
        const response = await axios.get(`/permissions/${this.projectId}`);
        this.permissions = response.data.data;
        this.groupPermissions();
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    },
    groupPermissions() {
      this.permissionGroups = {
        tools: [],
        conditions: [],
        locations: [],
        other: []
      };
      this.permissions.forEach(permission => {
        if (this.permissionGroups[permission.type]) {
          this.permissionGroups[permission.type].push(permission);
        }
      });
    },
    async fetchTeams() {
      try {
        const response = await axios.get(`/teams`, { params: { project_id: this.projectId } });
        this.dynamicTeams = response.data.data || [];
        if (this.dynamicTeams.length > 0) {
          this.selectedTeam = this.dynamicTeams[0].id;
          this.activeTeamName = this.dynamicTeams[0].name;
        } else {
          this.showCreateModal = true;
        }
      } catch (error) {
        this.toast.error("Failed to fetch teams.");
        console.error("Error fetching teams:", error);
      }
    },
    async fetchProvinces() {
      try {
        const response = await axios.get("/province");
        this.provinces = response.data.data || [];
      } catch (error) {
        this.toast.error("Failed to fetch provinces.");
      }
    },
    async fetchDistricts() {
      if (!this.formData.province_id) return;
      try {
        const response = await axios.get(`/get-districts/${this.formData.province_id}`);
        this.districts = response.data.data || [];
      } catch (error) {
        this.toast.error("Failed to fetch districts.");
      }
    },
    setActiveTeam(teamId, teamName = "Admins") {
      this.selectedTeam = teamId;
      this.activeTeamName = teamName;
    },
    async createTeam() {
      if (!this.newTeamName) return;

      try {
        const response = await axios.post("/teams", {
          name: this.newTeamName,
          project_id: this.projectId,
        });
        if (response.data.status) {
          const newTeam = response.data.data;
          this.dynamicTeams.push(newTeam);
          this.setActiveTeam(newTeam.id, newTeam.name);
          this.closeModal();
          this.toast.success("Team created successfully!");
        }
      } catch (error) {
        this.toast.error("Failed to create team.");
        console.error("Error creating team:", error);
      }
    },
    closeModal() {
      this.showCreateModal = false;
      this.newTeamName = "";
    },
    async createUser() {
      this.showSpinner = true;

      // Prepare permissions as a flat array
      const flattenedPermissions = [
        ...this.formData.permissions.tools,
        ...this.formData.permissions.conditions,
        ...this.formData.permissions.locations,
        ...this.formData.permissions.other,
      ]
        .filter(permission => permission) 
        .map(permission => Number(permission)) 
        .filter(permission => !isNaN(permission));

      console.log("Flattened Permissions:", flattenedPermissions);

      const payload = {
        project_id: this.projectId,
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        email: this.formData.email || null,
        phone: this.formData.phone,
        gender: this.formData.gender,
        password: this.formData.password,
        user_type: "admin",
        province_id: this.formData.province_id || null,
        district_id: this.formData.district_id || null,
        permissions: flattenedPermissions,
      };

      try {
        const response = await axios.post("/admin/create-admin", payload);
        if (response.data.status) {
          this.toast.success("Admin user created successfully!");
          this.$router.push({ name: "admins" });
        }
      } catch (error) {
        console.error("Error creating admin user:", error);
        if (error.response && error.response.data.errors) {
          this.toast.error(JSON.stringify(error.response.data.errors));
        } else {
          this.toast.error("Failed to create admin user.");
        }
      } finally {
        this.showSpinner = false;
      }
    },
    async handleSubmit() {
      if (this.selectedTeam === 'admin') {
        await this.createUser();
        return;
      }
      const payload = {
        project_id: this.projectId,
        team_id: this.selectedTeam,
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        email: `${this.formData.first_name}.${this.formData.last_name}@example.com`.toLowerCase(),
        phone: this.formData.phone,
        gender: this.formData.gender,
        nid: this.formData.nid,
        password: this.formData.password,
      };

      try {
        const response = await axios.post("/user/signup", payload);
        if (response.data.status) {
          this.toast.success("User created successfully!");
          this.resetForm();
        }
      } catch (error) {
        this.toast.error("Failed to create user.");
        console.error("Error creating user:", error);
      }
    },
    resetForm() {
      this.formData = {
        first_name: "",
        last_name: "",
        phone: "",
        gender: "",
        nid: "",
        password: "",
        province_id: "",
        district_id: "",
      };
      this.districts = [];
    },
    capitalizeFirstLetter(string) {
      if (!string || typeof string !== 'string') {
        return '';
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    logAndCapitalize(string) {
      return this.capitalizeFirstLetter(string);
    },
  },
};
</script>


<style lang="scss" scoped>
.page-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.sidebar {
  width: 25%;
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 15px;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;

      a {
        text-decoration: none;
        color: #333;
        padding: 10px;
        display: block;
        border-radius: 8px;

        &:hover {
          background-color: #e8f0fe;
          color: #0a2472;
        }

        &.active-link {
          background-color: #0a2472;
          color: white;
          font-weight: bold;
        }
      }
    }
  }

  .create-team-btn {
    background: #0a2472;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #1e3a8a;
    }
  }
}

.form-container {
  width: 75%;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  p {
    margin-bottom: 20px;
    font-weight: bold;
  }

  .row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .col {
      flex: 1;

      .input-container {
        position: relative;

        input,
        select {
          width: 100%;
          padding: 10px;
          border: 1px solid #d1d5db;
          border-radius: 6px;
          outline: none;

          &:focus {
            border-color: #0a2472;
          }
        }

        label {
          position: absolute;
          top: -10px;
          left: 10px;
          background: white;
          padding: 0 5px;
          font-size: 12px;
          color: #6b7280;
        }
      }
    }
  }

  button {
    background-color: #0a2472;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #1e3a8a;
    }
  }

  .permissions-section {
    border: 1px solid #e4e7ec;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;

    h3 {
      margin-bottom: 10px;
    }

    .permissions-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;

      .permission-group {
        h4 {
          font-size: 1.1em;
          margin-bottom: 10px;
        }

        h5 {
          cursor: pointer;
          margin: 5px 0;
          font-size: 1em;

          &:hover {
            color: var(--blue-500);
          }
        }

        label {
          display: block;
          margin-bottom: 5px;
          cursor: pointer;

          input {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 470px;

    h3 {
      margin-bottom: 15px;
    }

    .input-container {
      margin-bottom: 15px;

      label {
        display: block;
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }

    .btn-container {
      display: flex;
      justify-content: space-between;

      button {
        background: #0a2472;
        color: white;
        border: none;
        padding: 8px 12px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background: #1e3a8a;
        }

        &:nth-child(2) {
          background: #ccc;
          color: #333;
        }
      }
    }
  }
}
</style>

<template>
    <div class="container">
        <!-- Steps Navigation -->
        <div class="steps-navigation">
            <div v-for="(step, index) in steps" :key="index"
                :class="['step', { active: currentStep === step.id, completed: step.completed }]">
                <div class="circle">
                    <span v-if="step.completed" class="checkmark">✔</span>
                    <span v-else>{{ step.id }}</span>
                </div>
                <div class="label">{{ step.name }}</div>
            </div>
        </div>

        <!-- Step 1: Project Creation -->
        <div class="form-card" v-if="currentStep === 1">
            <h2>New Project</h2>
            <form @submit.prevent="handleCreateProject">
                <div class="form-group">
                    <label for="name">Project Name</label>
                    <input v-model="project.name" type="text" placeholder="Enter Project Name" required />
                </div>
                <div class="form-group">
                    <label for="description">Project Description</label>
                    <textarea v-model="project.descriptions" placeholder="Enter Project Description"
                        required></textarea>
                </div>
                <div class="form-group sponsors-group">
                    <label>Sponsors</label>
                    <div class="sponsor-input">
                        <input v-model="sponsorInput" type="text" placeholder="Add Sponsor" />
                        <button type="button" @click="addSponsor" class="add-sponsor-btn">+</button>
                    </div>
                    <ul class="sponsor-list">
                        <li v-for="(sponsor, index) in project.sponsors" :key="index">
                            {{ sponsor }}
                            <span @click="removeSponsor(index)" class="remove-sponsor">🗑</span>
                        </li>
                    </ul>
                </div>
                <center><br>
                    <button type="submit" class="next-btn">Save & Next</button>
                </center>

            </form>
        </div>

        <!-- Step 2: Admin Creation -->
        <div class="form-card" v-if="currentStep === 2">
            <h2>Create Admin</h2>
            <form @submit.prevent="handleCreateAdmin">
                <div class="form-row">
                    <div class="form-group">
                        <label for="first_name">First Name</label>
                        <input v-model="admin.first_name" type="text" placeholder="First Name" required />
                    </div>
                    <div class="form-group">
                        <label for="last_name">Last Name</label>
                        <input v-model="admin.last_name" type="text" placeholder="Last Name" required />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group">
                        <label for="phone">Phone Number</label>
                        <input v-model="admin.phone" type="text" placeholder="Phone Number" required />
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input v-model="admin.email" type="email" placeholder="Email" required />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group">
                        <label>Gender</label>
                        <select v-model="admin.gender" required>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input v-model="admin.password" type="password" placeholder="Password" required />
                    </div>
                </div>
                <div class="button-group">
                    <button type="button" @click="currentStep = 1" class="back-btn">Back</button>
                    <button type="submit" class="done-btn">Done</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "@/helpers/axios";
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            currentStep: 1,
            steps: [
                { id: 1, name: "Create Project", completed: false },
                { id: 2, name: "Create Admin", completed: false },
            ],
            project: {
                name: "",
                descriptions: "",
                sponsors: [],
            },
            sponsorInput: "",
            admin: {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                gender: "Male",
                user_type: "admin",
                password: "",
                permissions: [1],
                project_id: null,
            },
        };
    },
    methods: {
        addSponsor() {
            if (this.sponsorInput.trim()) {
                this.project.sponsors.push(this.sponsorInput.trim());
                this.sponsorInput = "";
            }
        },
        removeSponsor(index) {
            this.project.sponsors.splice(index, 1);
        },
        async handleCreateProject() {
            try {
                const response = await axios.post("/projects", this.project);
                this.admin.project_id = response.data.data.id;
                this.steps[0].completed = true;
                this.currentStep = 2;
                Toast.success("Project created successfully!");
            } catch (error) {
                Toast.error("Failed to create project.");
            }
        },
        async handleCreateAdmin() {
            try {
                await axios.post("/admin/create-admin", this.admin);
                Toast.success("Admin created successfully!");
                this.$router.push({ name: "projects" });
            } catch (error) {
                Toast.error("Failed to create admin.");
            }
        },
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    font-family: Arial, sans-serif;
}

/* Steps Navigation */
.steps-navigation {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 20px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999;
}

.step.active {
    color: #0a2472;
}

.step.completed .circle {
    background-color: #28a745;
}

.circle {
    width: 30px;
    height: 30px;
    border: 2px solid #0a2472;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-weight: bold;
}

.checkmark {
    color: #fff;
}

/* Form Card */
.form-card {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 70%;
    margin-top: 18px;
}

h2 {
    text-align: center;
    color: #0a2472;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

input,
textarea,
select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Sponsor Input */
.sponsor-input {
    display: flex;
    gap: 10px;
}

.add-sponsor-btn {
    background-color: #0a2472;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.sponsor-list {
    list-style: none;
    padding: 0;
}

.sponsor-list li {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.remove-sponsor {
    color: red;
    cursor: pointer;
}

/* Buttons */
.button-group {
    display: flex;
    justify-content: space-between;
}

.next-btn,
.done-btn,
.back-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.next-btn,
.done-btn {
    background-color: #0a2472;
    color: #fff;
}

.back-btn {
    background-color: #ccc;
    color: #000;
}
</style>
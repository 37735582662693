<template>
    <div class="card">
        <div class="card-header">
            <h3>Sexual Orientation Distribution</h3>
            <button style="margin-left: 59rem" @click="copyChart" class="copy-button">
                <i class="fa fa-copy"></i> Copy
            </button>
        </div>
        <div class="custom-legend">
            <span v-for="(label, index) in chartData.labels" :key="index" class="legend-item"
                @click="toggleLegend(index)">
                <span class="legend-color" :style="{
                    backgroundColor: legendState[index] ? dynamicColors[index % dynamicColors.length] : '#d3d3d3',
                }"></span>
                {{ label }}
            </span>
        </div>
        <div class="chart-container" ref="chartContainer"></div>

        <div class="dropdown-container">
            <div class="container">
                <select v-model="selectedTool" @change="handleToolChange">
                    <option disabled value="">Select Tool</option>
                    <option value="5" v-if="hasPermission('tools', 'service_user_survey')">Service User</option>
                    <option value="6" v-if="hasPermission('tools', 'facility_manager_survey')">Facility Manager Survey
                    </option>
                    <option value="7" v-if="hasPermission('tools', 'ayp_survey')">AYP Survey</option>
                    <option value="8" v-if="hasPermission('tools', 'kp_survey')">KP Survey</option>
                    <option value="9" v-if="hasPermission('tools', 'observation_survey')">Observation Survey</option>
                </select>
            </div>
            <div class="container">
                <select v-model="selectedProvince" @change="fetchDistricts">
                    <option disabled value="">Select Province</option>
                    <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}
                    </option>
                </select>
            </div>
            <div class="container">
                <select v-model="selectedDistrict" @change="fetchSites">
                    <option disabled value="">Select District</option>
                    <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}
                    </option>
                </select>
            </div>
            <div class="container">
                <select v-model="selectedSite" @change="fetchChartData">
                    <option disabled value="">Select Site</option>
                    <option v-for="site in sites" :key="site.id" :value="site.id">{{ site.health_center_name }}</option>
                </select>
            </div>
        </div>
    </div>
</template>


<script>
import * as echarts from 'echarts';
import axios from "@/helpers/axios.js";
import { mapGetters } from 'vuex';
import { useToast } from "vue-toastification";

const Toast = useToast();

export default {
    data() {
        return {
            provinces: [],
            districts: [],
            sites: [],
            selectedTool: "5",
            selectedProvince: "",
            selectedDistrict: "",
            selectedSite: "",
            chartInstance: null,
            dynamicColors: [
                "#5470C6", // Soft Blue
                "#F76C6C", // Light Pink
                "#A27DFF", // Soft Purple
                "#73C0DE", // Light Cyan
                "#91CC75", // Soft Green
                "#FAC858", // Yellow
            ],
            chartData: {
                labels: [],
                values: [],
            },
            legendState: {},
        };
    },
    mounted() {
        this.fetchProvinces();
        this.initChart();
        this.fetchChartData();
        window.addEventListener("resize", this.resizeChart);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resizeChart);
        if (this.chartInstance) this.chartInstance.dispose();
    },
    computed: {
        ...mapGetters({
            hasPermission: "hasPermission",
            selectedLanguage: "selectedLanguage",
        }),

        questionId() {
            return this.selectedLanguage === 1 ? 2279 : 2209;
        },
    },
    watch: {
        selectedLanguage() {
            this.fetchChartData();
        },
    },
    methods: {
        toggleLegend(index) {
            this.legendState[index] = !this.legendState[index];

            const filteredValues = this.chartData.values.map((value, idx) => {
                return this.legendState[idx] ? value : 0;
            });

            this.updateChart(filteredValues);
        },
        handleToolChange() {
            console.log("Tool changed to: ", this.selectedTool);
            this.fetchChartData();
        },

        initChart() {
            this.$refs.chartContainer.style.height = "500px";
            this.chartInstance = echarts.init(this.$refs.chartContainer);

            if (this.chartData.labels.length > 0) {
                this.legendState = this.chartData.labels.reduce((acc, _, index) => {
                    acc[index] = true;
                    return acc;
                }, {});
            }
        },
        async fetchProvinces() {
            try {
                const response = await axios.get("/province/1");
                this.provinces = response.data.data;
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        },
        async fetchDistricts() {
            try {
                const response = await axios.get(`/districts/${this.selectedProvince}`);
                this.districts = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        },
        async fetchSites() {
            if (!this.selectedDistrict) return;
            try {
                const response = await axios.get(`/health-centers?district_id=${this.selectedDistrict}`);
                this.sites = response.data.data;
                this.fetchChartData();
            } catch (error) {
                console.error("Error fetching sites:", error);
            }
        },
        async fetchChartData() {
            try {
                const payload = {
                    tool_id: this.selectedTool,
                    question_id: 2279,
                    district_id: this.selectedDistrict,
                    site_id: this.selectedSite,
                };
                const response = await axios.post("/answers/responses", payload);

                if (response.data.status && response.data.data.length > 0) {
                    const data = this.selectedLanguage === 1
                        ? response.data.data.map(item => ({
                            label: item.eng_response,
                            value: item.count,
                        }))
                        : response.data.data.map(item => ({
                            label: item.response,
                            value: item.count,
                        }));

                    this.chartData.labels = data.map(item => item.label);
                    this.chartData.values = data.map(item => item.value);

                    this.legendState = this.chartData.labels.reduce((acc, _, index) => {
                        acc[index] = true;
                        return acc;
                    }, {});

                    this.updateChart();
                } else {
                    console.error("No results found or API returned an error.");
                    this.clearChart();
                }
            } catch (error) {
                console.error("Error fetching chart data:", error);
                this.clearChart();
            }
        },

        clearChart() {
            this.chartData.labels = [];
            this.chartData.values = [];
            this.legendState = {};

            if (this.chartInstance) {
                const emptyOption = {
                    series: [
                        {
                            type: "bar",
                            data: [],
                        },
                    ],
                };
                this.chartInstance.setOption(emptyOption);
            }
        },

        prepareChartData(data) {
            return {
                labels: data.map(item => item.response),
                values: data.map(item => item.count),
            };
        },
        updateChart(filteredValues = this.chartData.values) {
            const option = {
                tooltip: {
                    trigger: "item",
                    formatter: params => `${params.name}: ${params.value}`,
                },
                grid: {
                    top: "15%",
                    left: "5%",
                    right: "5%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: this.chartData.labels,
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: { color: "#ddd" },
                    },
                },
                yAxis: {
                    type: "value",
                    axisLabel: {
                        fontSize: 12,
                        color: "#666",
                    },
                    splitLine: {
                        lineStyle: { color: "#f0f0f0" },
                    },
                },
                series: [
                    {
                        type: "bar",
                        data: filteredValues.map((value, index) => ({
                            value,
                            itemStyle: {
                                color: this.dynamicColors[index % this.dynamicColors.length],
                                borderRadius: [10, 10, 0, 0],
                            },
                        })),
                        label: {
                            show: true,
                            position: "top",
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                        },
                    },
                ],
            };
            if (this.chartInstance) {
                this.chartInstance.setOption(option);
            }
        },

        async copyChart() {
            if (this.chartInstance) {
                try {
                    const imageBase64 = this.chartInstance.getDataURL({
                        type: 'png',
                        backgroundColor: '#fff',
                        pixelRatio: 2,
                    });

                    const img = new Image();
                    img.src = imageBase64;

                    // Copy to clipboard
                    const blob = await fetch(imageBase64).then((res) => res.blob());
                    const clipboardItem = new ClipboardItem({ 'image/png': blob });
                    await navigator.clipboard.write([clipboardItem]);

                    Toast.success('Chart copied to clipboard!');
                } catch (error) {
                    console.error('Failed to copy chart:', error);
                    Toast.error('Failed to copy chart.');
                }
            }
        },

        resizeChart() {
            if (this.chartInstance) {
                this.chartInstance.resize();
            }
        },
    },
};
</script>


<style lang="scss" scoped>
.card {
    width: 100%;
    margin: 0 auto;
    height: 600px;
    box-shadow: 0 0 14px rgb(206, 201, 201);
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 15px;

}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    h3 {
        margin: 0;
        // font-weight: 800;
        // font-size: 20px;
    }

    .copy-button {
        margin-left: 800px;
        padding: 8px 12px;
        background-color: #454545;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #555555;
        }
    }
}

.chart-container {
    width: 100%;
    height: 450px !important;
}

.dropdown-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 20px;
    width: 60%;
}

.container {
    position: relative;
    flex: 1;
}

select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: transparent;
    color: #39325a;
}

.custom-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-size: 14px;
    color: #666;
    cursor: pointer;
}

.legend-color {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    border-radius: 4px;
}
</style>
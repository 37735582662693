<template>
  <div class="projects-page">
    <div class="header">
      <button class="add-project-btn" @click="redirectToAddProject">
        + Add New Project
      </button>
    </div>

    <div v-if="loading" class="loading-container">
      <p>Loading Projects...</p>
    </div>
    <div v-else class="projects-grid">
      <div v-for="project in projects" :key="project.id" class="project-card">
        <div class="card-header">
          <h2>{{ project.name }}</h2>
          <p class="sponsors">
            Sponsors:
            <span v-for="sponsor in parseSponsors(project.sponsors)" :key="sponsor">
              {{ sponsor }}
            </span>
          </p>
        </div>
        <div class="card-body">
          <p>{{ project.descriptions }}</p>
        </div>
        <div class="card-footer">
          <small>Created at: {{ formatDate(project.created_at) }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios.js";

export default {
  data() {
    return {
      projects: [],
      loading: true,
    };
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await axios.get("/projects");
        if (response.data.status) {
          this.projects = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        this.loading = false;
      }
    },
    parseSponsors(sponsors) {
      return JSON.parse(sponsors);
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    redirectToAddProject() {
      this.$router.push({ name: "new_projects" });
    },
  },
};
</script>

<style scoped>
.projects-page {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  color: #333;
  margin-top: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  color: #0a2472;
  font-size: 28px;
}

.add-project-btn {
  background-color: #003c8be0;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.add-project-btn:hover {
  background-color: #08479ae0;
}

/* Loading State */
.loading-container {
  text-align: center;
  font-size: 18px;
  color: #555;
}

/* Projects Grid */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* Project Card */
.project-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
  background-color: #003c8be0;
  color: #fff;
  padding: 16px;
}

.card-header h2 {
  margin: 0;
  font-size: 20px;
}

.sponsors span {
  display: inline-block;
  background-color: #ffffff33;
  color: #fff;
  padding: 3px 8px;
  margin: 2px 5px 0 0;
  border-radius: 4px;
  font-size: 12px;
}

.card-body {
  padding: 16px;
  font-size: 14px;
  color: #555;
}

.card-footer {
  padding: 12px 16px;
  background-color: #f1f1f1;
  font-size: 12px;
  color: #777;
  text-align: right;
}
</style>

<template>
  <div class="forum-container">
    <div class="forum-header">
      <ul>
        <li :class="{ active: selectedToolId === null }" @click="fetchForum(null)">All Tools</li>
        <li v-for="tool in tools" :key="tool.id" :class="{ active: selectedToolId === tool.id }"
          @click="fetchForum(tool.id)">
          {{ tool.name }}
        </li>
      </ul>
    </div>

    <div v-if="loading" class="loading-spinner">
      <center>Loading...</center>
    </div>

    <div v-else class="forum-content">
      <div v-if="forums.length === 0" class="no-data-message">
        <center>
          <p>No forums found for the selected project.</p>
        </center>
      </div>

      <div v-else>
        <div v-for="(forum, index) in forums" :key="index" class="forum-card">
          <div class="forum-card-header">
            <div class="user-info">
              <h1>{{ forum.participant.full_name || "Anonymous member" }}</h1>
              <p>{{ forum.participant.type }}</p>
            </div>

            <div class="status">
              <div v-if="forum.status === 'resolved'" class="status-badge resolved">Resolved</div>
              <div v-else class="status-dropdown">
                <select v-model="forum.newStatus" @change="updateStatus(forum)">
                  <option value="pending">Pending</option>
                  <option value="progress">In Progress</option>
                  <option value="resolved">Resolved</option>
                </select>
              </div>
              <p class="timestamp">{{ formatTimeAgo(forum.created_at) }}</p>
            </div>
          </div>

          <div class="forum-card-body">
            <p>{{ forum.interviewee_comment }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "@/helpers/axios.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      tools: [],
      forums: [],
      selectedToolId: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.selectedProjectId,
      selectedLanguage: "selectedLanguage",
    }),
  },
  watch: {
    projectId(newProjectId, oldProjectId) {
      if (newProjectId !== oldProjectId) {
        this.fetchTools();
        this.fetchForum(null);
      }
    },
    selectedLanguage(newLanguage, oldLanguage) {
      if (newLanguage !== oldLanguage) {
        // Call fetchForum when language changes
        this.fetchForum(this.selectedToolId);
      }
    },
  },
  mounted() {
    this.fetchTools();
    this.fetchForum(null);
  },
  methods: {
    async fetchTools() {
      try {
        const response = await axios.get(`/all/tools`, {
          params: { project_id: this.projectId },
        });
        this.tools = response.data.data || [];
      } catch (error) {
        console.error("Error fetching tools:", error);
      }
    },
    async fetchForum(toolId) {
      this.selectedToolId = toolId;
      this.loading = true;
      this.forums = [];

      try {
        let endpoint = "/admin/forum"; // Default endpoint
        const params = {
          project_id: this.projectId,
        };

        // Adjust endpoint and parameters dynamically
        if (toolId === null) {
          if (this.selectedLanguage === 1) {
            // For language 1, use /all-tools endpoint
            endpoint = "/all-tools";
          } else {
            // For other languages, use the default /admin/forum endpoint
            endpoint = "/admin/forum";
          }
        } else if (this.selectedLanguage === 1) {
          // Specific endpoints for selectedLanguage === 1
          switch (toolId) {
            case 5:
              endpoint = "/service-user";
              break;
            case 6:
              endpoint = "/facility-manager";
              break;
            case 7:
              endpoint = "/ayp-survey";
              break;
            case 8:
              endpoint = "/kp-survey";
              break;
            case 9:
              endpoint = "/observation-survey";
              break;
            default:
              endpoint = "/admin/forum";
          }
          params.tool_id = toolId; // Include tool_id for specific tools
        } else {
          // Default behavior for other languages
          params.tool_id = toolId;
        }

        // Include selectedLanguage in params for dynamic translation
        params.language = this.selectedLanguage;

        const response = await axios.get(endpoint, { params });

        this.forums = response.data.data.map((forum) => ({
          ...forum,
          newStatus: forum.status, // Initialize newStatus
        }));
      } catch (error) {
        console.error("Error fetching forums:", error);
        this.forums = []; // Clear data on error as well
      } finally {
        this.loading = false;
      }
    },

    async updateStatus(forum) {
      const confirmed = window.confirm("Are you sure you want to update the status?");
      if (confirmed) {
        try {
          await axios.post("/admin/update-status", {
            forum_id: forum.id,
            status: forum.newStatus,
          });
          this.fetchForum(this.selectedToolId); // Refresh forum data
        } catch (error) {
          console.error("Error updating status:", error);
        }
      }
    },
    formatTimeAgo(timestamp) {
      const now = new Date();
      const past = new Date(timestamp);
      const diffInSeconds = Math.floor((now - past) / 1000);

      const minutes = Math.floor(diffInSeconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);

      if (years > 0) {
        return years === 1 ? "1 year ago" : `${years} years ago`;
      } else if (months > 0) {
        return months === 1 ? "1 month ago" : `${months} months ago`;
      } else if (weeks > 0) {
        return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
      } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
      } else if (hours > 0) {
        return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
      } else if (minutes > 0) {
        return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
      } else {
        return "just now";
      }
    },
  },
};
</script>



<style lang="scss" scoped>
.forum-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .forum-header ul {
    display: flex;
    gap: 20px;
    list-style: none;
    font-size: 16px;
    font-weight: bold;

    li {
      cursor: pointer;
      color: #667085;
      padding: 10px 15px;

      &.active {
        color: #0a2472;
        border-bottom: 3px solid #0a2472;
        font-weight: bold;
      }

      &:hover {
        color: #0a2472;
      }
    }
  }

  .forum-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .forum-card {
      border: 1px solid #e4e7ec;
      border-radius: 10px;
      padding: 30px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .forum-card-header {
        display: flex;
        justify-content: space-between;

        .user-info h1 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }

        .status {
          display: flex;
          align-items: center;
          gap: 10px;

          .status-dropdown select {
            padding: 4px 8px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 14px;
            color: #333;
          }

          .status-badge {
            display: inline-block;
            padding: 4px 10px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: bold;

            &.resolved {
              background-color: #d4edda;
              color: #155724;
            }
          }

          .timestamp {
            font-size: 12px;
            color: #999;
          }
        }
      }

      .forum-card-body {
        margin-top: 10px;
        font-size: 14px;
        color: #555;
      }
    }
  }
}
</style>

<template>
    <div class="created-dashboard">
        <div class="header-container">
            <h2 class="dashboard-title">Dashboard: {{ dashboardData.name }}</h2>
            <!-- <div class="custom-select-wrapper">
                <select id="language-select" class="custom-select" v-model="selectedLanguage" @change="switchLanguage">
                    <option value="1">Kinyarwanda</option>
                    <option value="2">English</option>
                </select>
            </div> -->
            <div class="save-button-container">
                <button class="save-button" @click="saveChartSettings">
                    <i class="fas fa-save"></i> Save Changes
                </button>
            </div>
        </div>
        <div v-if="isLoading" class="loading-container">
            <p>Loading...</p>
        </div>

        <div v-else>
            <!-- Conditional Rendering for Table Type -->
            <div v-if="dashboardData.type === 'table'" class="table-container">
                <button @click="downloadExcel" class="download-button">
                    <i class="fa fa-download"></i> Download Excel
                </button>
                <table>
                    <thead>
                        <tr>
                            <th>District</th>
                            <th>Tool</th>
                            <th>Condition</th>
                            <th>Questions</th>
                            <th>Responses</th>
                            <th>Site Name</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(district, dIndex) in tableData" :key="'district-' + dIndex">
                            <template v-for="(tool, tIndex) in district.tools" :key="'tool-' + tIndex">
                                <template v-for="(condition, cIndex) in tool.conditions" :key="'condition-' + cIndex">
                                    <template v-for="(question, qIndex) in condition.questions"
                                        :key="'question-' + qIndex">
                                        <template v-for="(response, rIndex) in question.responses"
                                            :key="'response-' + rIndex">
                                            <template v-for="(site, sIndex) in response.sites" :key="'site-' + sIndex">
                                                <tr>
                                                    <!-- District -->
                                                    <td v-if="tIndex === 0 && cIndex === 0 && qIndex === 0 && rIndex === 0 && sIndex === 0"
                                                        :rowspan="district.tools.reduce((total, tool) => total + tool.conditions.reduce((cTotal, condition) => cTotal + condition.questions.reduce((qTotal, question) => qTotal + question.responses.reduce((rTotal, response) => rTotal + response.sites.length, 0), 0), 0), 0)">
                                                        {{ district.district_name }}
                                                    </td>

                                                    <!-- Tool -->
                                                    <td v-if="cIndex === 0 && qIndex === 0 && rIndex === 0 && sIndex === 0"
                                                        :rowspan="tool.conditions.reduce((cTotal, condition) => cTotal + condition.questions.reduce((qTotal, question) => qTotal + question.responses.reduce((rTotal, response) => rTotal + response.sites.length, 0), 0), 0)">
                                                        <span v-if="selectedLanguage === 1">{{ tool.tool_name }}</span>
                                                        <span v-else>{{ tool.kiny_tool_name }}</span>
                                                    </td>

                                                    <!-- Condition -->
                                                    <td v-if="qIndex === 0 && rIndex === 0 && sIndex === 0"
                                                        :rowspan="condition.questions.reduce((qTotal, question) => qTotal + question.responses.reduce((rTotal, response) => rTotal + response.sites.length, 0), 0)">
                                                        {{ condition.condition_name }}
                                                    </td>

                                                    <!-- Question -->
                                                    <td v-if="rIndex === 0 && sIndex === 0"
                                                        :rowspan="question.responses.reduce((rTotal, response) => rTotal + response.sites.length, 0)">
                                                        <span v-if="selectedLanguage === 1">{{ question.eng_question
                                                            }}</span>
                                                        <span v-else>{{ question.question }}</span>
                                                    </td>

                                                    <!-- Response -->
                                                    <td v-if="sIndex === 0" :rowspan="response.sites.length">
                                                        <span v-if="selectedLanguage === 1">{{ response.eng_response
                                                            }}</span>
                                                        <span v-else>{{ response.response }}</span>
                                                    </td>

                                                    <!-- Site Name -->
                                                    <td>{{ site.site_name }}</td>

                                                    <!-- Count -->
                                                    <td>{{ site.response_count }}</td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>

                    </tbody>
                </table>
            </div>

            <!-- Grid Layout for Non-Table Types -->
            <div v-else class="grid-container">
                <grid-layout v-if="layout.length > 0" :layout.sync="layout" :col-num="8" :row-height="115"
                    :is-draggable="draggable" :is-resizable="resizable" :responsive="responsive"
                    :vertical-compact="true" :use-css-transforms="true" @layout-updated="onLayoutUpdated"
                    @resize="resizeCharts">

                    <grid-item v-for="(item, index) in layout" :key="item.i" :x="item.x" :y="item.y" :w="item.w"
                        :h="item.h" :i="item.i">
                        <div class="cards">
                            <div class="card-header">
                                <button class="remove-button" @click="removeChart(item.i)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                <div class="filter-select">
                                    <select v-model="dashboardData.chart_types[item.i]"
                                        @change="updateChartType(item.i)">
                                        <option disabled value="">Chart Type</option>
                                        <option value="bar">Bar</option>
                                        <option value="line">Line</option>
                                        <option value="doughnut">Doughnut</option>
                                        <option value="funnel">Funnel</option>
                                        <option value="pie">Pie</option>
                                        <option value="polarEndAngle">Polar End Angle</option>
                                        <option value="stackedHorizontalBar">Stacked Horizontal Bar</option>
                                        <option value="radar">Basic Radar Chart</option>
                                        <option value="gradientBar">Gradient Bar</option>
                                    </select>
                                </div>
                            </div>

                            <h3 class="question-title" v-if="selectedLanguage === 1">
                                {{ dashboardData.type === 'hc_graph'
                                    ? `Health Center Data: ${dashboardData.charts_data.questions[index].eng_question}`
                                    : dashboardData.charts_data.questions[index].eng_question }}
                            </h3>
                            <h3 class="question-title" v-else>
                                {{ dashboardData.type === 'hc_graph'
                                    ? `Health Center Data: ${dashboardData.charts_data.questions[index].question}`
                                    : dashboardData.charts_data.questions[index].question }}
                            </h3>

                            <div v-if="dashboardData.type === 'hc_graph'" class="chart-container"
                                :id="'chart-question-' + index">
                            </div>
                            <div v-else class="chart-container" :id="'chart-' + index">
                            </div>
                        </div>
                    </grid-item>
                </grid-layout>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { GridLayout, GridItem } from "vue3-grid-layout";
import axios from "@/helpers/axios.js";
import * as echarts from "echarts";
import { useToast } from "vue-toastification";
import * as XLSX from "xlsx";
const Toast = useToast();

export default {
    components: {
        GridLayout,
        GridItem,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            dashboardData: {
                name: '',
                charts_data: {
                    questions: []
                },
                chart_types: {},
                type: ''
            },
            isLoading: false,
            tableData: [],
            layout: [],
            draggable: true,
            resizable: true,
            responsive: true,
            healthCenterNames: [],
        };
    },
    watch: {
        id(newId) {
            // Fetch dashboard data and reset the language based on saved value
            const savedLanguage = localStorage.getItem("selectedLanguage");
            this.selectedLanguage = savedLanguage || "1"; // Default to Kinyarwanda
            this.fetchDashboardData(newId);
        },
        // selectedLanguage(newLanguage, oldLanguage) {
        //     this.initializeCharts();
        // },
    },
    created() {
        // Retrieve the saved language from localStorage
        const savedLanguage = localStorage.getItem("selectedLanguage");
        this.selectedLanguage = savedLanguage || "1";

        this.fetchDashboardData(this.id);
        const userId = JSON.parse(localStorage.getItem("user")).id;
    },
    mounted() {
        window.addEventListener("resize", this.resizeCharts);
        this.$nextTick(() => {
            setTimeout(() => {
                this.resizeCharts();
            }, 100);
        });
    },
    computed: {
        ...mapGetters({
            hasPermission: "hasPermission",
        }),
        ...mapState({
            projectId: (state) => state.selectedProjectId, // Project ID from Vuex
            selectedLanguage: (state) => state.selectedLanguage,
        })
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resizeCharts);
    },

    methods: {
        onLayoutUpdated(updatedLayout) {
            this.layout = updatedLayout;
            updatedLayout.forEach((item, index) => {
                this.dashboardData.chart_sizes[index] = {
                    w: item.w,
                    h: item.h,
                };
            });
            this.resizeCharts();
        },
        async switchLanguage() {
            this.isLoading = true;

            try {
                if (this.selectedLanguage === 2) {
                    const response = await axios.post(
                        "https://endpoints.surveyhub.rw/analytics/translate-json/english",
                        this.dashboardData
                    );
                    if (response.data) {
                        this.dashboardData = response.data.data;
                        this.updateView();
                    } else {
                        Toast.error("Failed to fetch translated data.");
                    }
                } else {
                    this.fetchDashboardData(this.id);
                }

                // Save the selected language to localStorage
                localStorage.setItem("selectedLanguage", this.selectedLanguage);
            } catch (error) {
                console.error("Error switching language:", error);
                Toast.error("An error occurred while switching languages.");
            } finally {
                this.isLoading = false; // Hide loading indicator
            }
        },
        updateView() {
            if (this.dashboardData.type === "table") {
                this.fetchTableData(/* requestData based on dashboardData */);
            } else {
                this.initializeCharts();
            }
        },
        async fetchDashboardData(dashboardId) {
            this.isLoading = true;
            try {
                const response = await axios.get(`/dashboard/${dashboardId}`);
                if (response.data.status) {
                    this.dashboardData = response.data.data;

                    // Ensure chart_types has a valid structure
                    this.dashboardData.chart_types = this.dashboardData.chart_types || {};

                    if (this.dashboardData.type === "table") {
                        const requestData = {
                            condition_id: this.dashboardData.condition_id || [],
                            district_id: (this.dashboardData.district_id || []).map(Number),
                            health_center_id: this.dashboardData.health_center_id || [],
                            questions: this.dashboardData.questions || [],
                            response_options: [],
                            tool_id: this.dashboardData.tool_id || [],
                        };
                        await this.fetchTableData(requestData);
                    } else {
                        this.initializeLayout();
                        this.$nextTick(() => {
                            this.initializeCharts();
                            this.resizeCharts();
                        });
                    }
                } else {
                    console.error("Failed to fetch dashboard data");
                }
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            } finally {
                this.isLoading = false;
            }
        },

        async fetchTableData(requestData) {
            try {
                const response = await axios.post("/table/site-based-data", requestData);
                if (response.data.status) {
                    this.tableData = response.data.data || [];
                    console.log("Populated tableData:", this.tableData);

                    this.healthCenterNames = this.extractHealthCenterNames(this.tableData);
                } else {
                    console.warn("No data found for the table:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching table data:", error);
            }
        },
        extractHealthCenterNames(data) {
            const healthCenterNamesSet = new Set();
            data.forEach(question => {
                question.responses.forEach(response => {
                    response.health_centers.forEach(center => {
                        healthCenterNamesSet.add(center.health_center);
                    });
                });
            });
            return Array.from(healthCenterNamesSet);
        },

        initializeLayout() {
            if (this.dashboardData.charts_data && this.dashboardData.charts_data.questions) {
                this.layout = this.dashboardData.charts_data.questions.map((question, index) => {
                    const savedSize = this.dashboardData.chart_sizes[index] || {};
                    return {
                        x: index % 2 === 0 ? 0 : 6,
                        y: Math.floor(index / 2),
                        w: savedSize.w || 6, // Default to 6 columns wide
                        h: savedSize.h || 4, // Default to 4 rows high
                        i: `question-${index}`,
                    };
                });
            }
        },

        initializeCharts() {
            this.dashboardData.charts_data.questions = this.dashboardData.charts_data.questions.map((question, index) => ({
                ...question,
                id: this.dashboardData.questions[index] || `temp-${index}`, // Use mapped ID or generate temporary one
            }));

            this.dashboardData.charts_data.questions.forEach((question, index) => {
                const chartKey = question.id.toString();
                const chartType = this.dashboardData.chart_types[chartKey] || "bar";

                if (question.responses && question.responses.length > 0) {
                    if (this.dashboardData.type === "hc_graph") {
                        this.renderHealthCenterChart(question, index, chartType);
                    } else {
                        this.renderChart(question, index, chartType);
                    }
                }
            });
        },

        renderChart(question, index, chartType) {
            this.$nextTick(() => {
                const chartDom = document.getElementById("chart-" + index);
                if (!chartDom) {
                    console.warn(`Chart DOM not found for question at index ${index}`);
                    return;
                }

                // Reinitialize chart instance
                const chart = echarts.init(chartDom);

                const selectedChartType = chartType || this.dashboardData.chart_types[question.id.toString()] || "line";
                const options = this.getChartOptions(question, selectedChartType);

                if (Object.keys(options).length > 0) {
                    chart.setOption(options);

                    setTimeout(() => {
                        chart.resize();
                    }, 100);

                    // Add interactive styles for pie/doughnut charts
                    if (chartType === 'doughnut') {
                        chart.on('mouseover', (params) => {
                            if (params.seriesType === 'pie' || params.seriesType === 'doughnut') {
                                chart.setOption({
                                    graphic: {
                                        type: 'text',
                                        left: 'center',
                                        top: 'center',
                                        style: {
                                            text: `${params.name}`,
                                            fontSize: 32,
                                            fontFamily: 'Roboto, sans-serif',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            fill: '#333',
                                            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)',
                                        }
                                    }
                                });
                            }
                        });
                    }

                    chart.on('mouseout', () => {
                        chart.setOption({
                            graphic: {
                                type: 'text',
                                left: 'center',
                                top: 'center',
                                style: {
                                    text: ''
                                }
                            }
                        });
                    });
                    chart.on('click', function (params) {
                        const zoomSize = 6;
                        const startIndex = Math.max(params.dataIndex - zoomSize / 2, 0);
                        const endIndex = Math.min(params.dataIndex + zoomSize / 2, question.responses.length - 1);
                        if (this.selectedLanguage === 1) {
                            chart.dispatchAction({
                                type: 'dataZoom',
                                startValue: question.responses[startIndex].eng_response,
                                endValue: question.responses[endIndex].eng_response,
                            });
                        }
                        else {
                            chart.dispatchAction({
                                type: 'dataZoom',
                                startValue: question.responses[startIndex].response,
                                endValue: question.responses[endIndex].response,
                            });
                        }
                    });

                } else {
                    console.warn("Skipping chart rendering due to invalid options.");
                }
            });
        },


        renderHealthCenterChart(question, index, chartType) {
            const chartDom = document.getElementById(`chart-question-${index}`);
            if (!chartDom) {
                console.warn(`Chart container not found for index ${index}`);
                return;
            }

            const chart = echarts.init(chartDom);

            const validChartTypes = ["bar", "line", "doughnut", "funnel", "pie", "polarEndAngle", "stackedHorizontalBar", "radar", "gradientBar"];
            const selectedChartType = validChartTypes.includes(chartType) ? chartType : "bar";

            const options = this.getHealthCenterChartOptions(question, selectedChartType);

            if (Object.keys(options).length > 0) {
                chart.setOption(options);
                setTimeout(() => {
                    chart.resize(); // Force resize after rendering
                }, 100); // Allow some delay to ensure container is stable
            } else {
                console.warn(`Invalid options for chart type: ${selectedChartType}`);
            }
        },

        // updateChartType(chartId) {
        //     const selectedType = this.dashboardData.chart_types[chartId] || "bar";

        //     // console.log(`Chart ID: ${chartId}, Selected Type: ${selectedType}`);

        //     // Locate the chart's layout index
        //     const index = this.layout.findIndex(item => item.i === chartId);
        //     if (index !== -1) {
        //         const question = this.dashboardData.charts_data.questions[index];

        //         // Dispose of any existing chart instance
        //         const chartDomId = this.dashboardData.type === "hc_graph" ? `chart-question-${index}` : `chart-${index}`;
        //         const chartDom = document.getElementById(chartDomId);
        //         if (chartDom) {
        //             const existingChart = echarts.getInstanceByDom(chartDom);
        //             if (existingChart) {
        //                 existingChart.dispose(); // Clear the chart instance
        //             }
        //         }

        //         // Render the chart with the updated type
        //         if (this.dashboardData.type === "hc_graph") {
        //             this.renderHealthCenterChart(question, index, selectedType);
        //         } else {
        //             this.renderChart(question, index, selectedType);
        //         }
        //     }
        // },
        updateChartType(chartId) {
            const selectedType = this.dashboardData.chart_types[chartId] || "bar";

            // Locate the chart's layout index
            const index = this.layout.findIndex((item) => item.i === chartId);
            if (index !== -1) {
                const question = this.dashboardData.charts_data.questions[index];

                // Dispose of any existing chart instance
                const chartDomId = this.dashboardData.type === "hc_graph" ? `chart-question-${index}` : `chart-${index}`;
                const chartDom = document.getElementById(chartDomId);
                if (chartDom) {
                    const existingChart = echarts.getInstanceByDom(chartDom);
                    if (existingChart) {
                        existingChart.dispose(); // Clear the chart instance
                    }
                }

                // Render the chart with the updated type
                if (this.dashboardData.type === "hc_graph") {
                    this.renderHealthCenterChart(question, index, selectedType);
                } else {
                    this.renderChart(question, index, selectedType);
                }

                // Update the chart_types with the new type while retaining other changes
                this.dashboardData.chart_types = {
                    ...this.dashboardData.chart_types, // Preserve existing types
                    [chartId]: selectedType, // Update the specific chart's type
                };
            }
        },
        removeChart(chartId) {
            // Remove the chart from layout
            this.layout = this.layout.filter(item => item.i !== chartId);

            // Remove the chart type from dashboard data
            delete this.dashboardData.chart_types[chartId];

            // Remove the corresponding question from `charts_data.questions`
            this.dashboardData.charts_data.questions = this.dashboardData.charts_data.questions.filter(
                (question, index) => `question-${index}` !== chartId
            );

            // Save the updated settings to the backend
            this.saveChartSettings();
        },
        getChartOptions(question, chartType) {
            const seriesData = question.responses.map((response, index) => ({
                name: response.response,
                value: response.count,
            }));
            const truncateLabel = (value) => {
                // Truncate labels to 10 characters and add ellipsis if necessary
                return value.length > 10 ? value.substring(0, 10) + '...' : value;
            };

            switch (chartType) {
                case 'doughnut':
                    return {
                        tooltip: {
                            trigger: 'item',
                            formatter: (params) => {
                                return `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${params.color};margin-right:5px;"></span> ${params.name}: ${params.value} (${params.percent}%)`;
                            }
                        },
                        legend: {
                            orient: 'horizontal',
                            top: 'top',
                            left: 'center'
                        },
                        graphic: {
                            type: 'text',
                            left: 'center',
                            top: '50%',
                            z: 100,
                            style: {
                                text: '',  // Initially empty; dynamically updated on hover
                                textAlign: 'center',
                                fontSize: 32,
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: 'bold',
                                fill: '#333',
                                textShadowBlur: 8,
                                textShadowOffsetX: 2,
                                textShadowOffsetY: 2,
                                textShadowColor: 'rgba(0, 0, 0, 0.25)'
                            }
                        },
                        series: [
                            {
                                name: question.question,
                                type: 'pie',
                                radius: ['40%', '70%'],
                                data: seriesData,
                                label: { show: false }  // Hides labels around the pie sections
                            }
                        ],
                        color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946']
                    };

                case 'line':
                    return {
                        tooltip: {
                            trigger: 'axis',
                            formatter: (params) => {
                                return params.map((p) => `${p.marker} ${p.name}: ${p.value}`).join('<br>');
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            axisLabel: {
                                interval: 0,
                                rotate: 45,
                                formatter: truncateLabel,
                            }
                        },
                        yAxis: { type: 'value' },
                        series: [
                            {
                                data: seriesData.map(item => item.value),
                                type: 'line',
                                smooth: true,
                                lineStyle: { width: 3, color: '#5470C6' },
                                symbol: 'circle',
                                symbolSize: 8,
                            }
                        ],
                    };

                case 'funnel':
                    return {
                        tooltip: { trigger: 'item' },
                        series: [
                            {
                                name: question.question,
                                type: 'funnel',
                                data: seriesData
                            }
                        ]
                    };

                case 'polarEndAngle':
                    return {
                        angleAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            startAngle: 75,
                        },
                        radiusAxis: {},
                        polar: {},
                        tooltip: { trigger: 'item' },
                        series: [{
                            type: 'bar',
                            data: seriesData.map(item => item.value),
                            coordinateSystem: 'polar',
                            itemStyle: { color: '#4C78A8' }
                        }],
                    };

                case 'stackedHorizontalBar':
                    return {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: { type: 'shadow' },
                            formatter: (params) => {
                                return params.map(p => `${p.marker} ${p.name}: ${p.value}`).join('<br>'); // Show full labels in tooltip
                            }
                        },
                        xAxis: {
                            type: 'value'
                        },
                        yAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            axisLabel: {
                                interval: 0,
                                rotate: 30,
                                formatter: (value) => {
                                    return value.length > 10 ? value.substring(0, 10) + '...' : value;
                                },
                                tooltip: {
                                    show: true,
                                    formatter: (value) => value
                                },
                            },
                        },
                        series: [
                            {
                                data: seriesData.map(item => item.value),
                                type: 'bar',
                                stack: 'total',
                                itemStyle: { borderRadius: [0, 10, 10, 0] }, // Rounded corners for better aesthetics
                            }
                        ],
                        color: ['#FF5A5F', '#57CC99', '#FFD166', '#4C78A8'],
                    };

                case 'gradientBar':
                    return {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                            },
                            formatter: function (params) {
                                const item = params[0];
                                const fullLabel = question.responses[item.dataIndex].response;
                                return `${fullLabel}: ${item.value}`;
                            },
                        },
                        xAxis: {
                            data: question.responses.map((response) =>
                                response.response.length > 5
                                    ? response.response.substring(0, 5) + '...'
                                    : response.response
                            ),
                            axisLabel: {
                                inside: true,
                                color: '#fff',
                                rotate: 0, // Change to 45 if needed for visibility
                                overflow: 'truncate', // Ensures no overflow
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                show: false,
                            },
                            z: 10,
                        },
                        yAxis: {
                            axisLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                color: '#999',
                            },
                            splitLine: {
                                show: false, // Removes horizontal grid lines
                            },
                        },
                        dataZoom: [
                            {
                                type: 'inside',
                            },
                        ],
                        series: [
                            {
                                type: 'bar',
                                showBackground: true,
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: '#83bff6' },
                                        { offset: 0.5, color: '#188df0' },
                                        { offset: 1, color: '#188df0' },
                                    ]),
                                },
                                emphasis: {
                                    itemStyle: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: '#2378f7' },
                                            { offset: 0.7, color: '#2378f7' },
                                            { offset: 1, color: '#83bff6' },
                                        ]),
                                    },
                                },
                                barBorderRadius: [10, 10, 0, 0], // Rounded corners at the top of the bars
                                data: question.responses.map((response) => response.count),
                            },
                        ],
                    };
                case 'pie':
                    return {
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b}: {c} ({d}%)',
                        },
                        legend: {
                            orient: 'horizontal',
                            top: 'top',
                            left: 'center',
                            data: seriesData.map(item => item.name),
                        },
                        series: [
                            {
                                name: question.question,
                                type: 'pie',
                                radius: '55%',
                                center: ['50%', '50%'],
                                data: seriesData,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    },
                                },
                            },
                        ],
                        color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                    };

                case 'radar':
                    return {
                        tooltip: {},
                        radar: {
                            indicator: question.responses.map(response => ({
                                name: response.response,
                                max: Math.max(...seriesData.map(item => item.value)) * 1.2,
                            })),
                            radius: 60,
                        },
                        series: [{
                            name: question.question,
                            type: 'radar',
                            data: [{ value: seriesData.map(item => item.value), name: question.question }],
                            itemStyle: { color: '#5470C6' },
                            areaStyle: { opacity: 0.3 },
                        }],
                    };

                case 'bar':
                default:
                    return {
                        tooltip: {
                            trigger: 'axis',
                            formatter: (params) => {
                                const dataPoint = params[0];
                                return `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${dataPoint.color};margin-right:5px;"></span> ${dataPoint.name}: ${dataPoint.value}`;
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: question.responses.map(response => response.response),
                            axisLabel: {
                                interval: 0, // Show all labels
                                rotate: 45, // Rotate to prevent overlap
                                overflow: 'truncate', // Ensure truncated text
                                width: 80, // Max width of the labels
                                ellipsis: true, // Add ellipsis
                                formatter: (value) => {
                                    return value.length > 10 ? value.substring(0, 10) + '...' : value;
                                },
                            },
                            tooltip: {
                                show: true, // Ensure tooltip displays full label
                                formatter: '{b}',
                            },
                        },
                        yAxis: { type: 'value' },
                        series: [{
                            data: seriesData.map(item => item.value),
                            type: 'bar',
                            itemStyle: { borderRadius: [10, 10, 0, 0] }
                        }]
                    };
            }
        },
        getHealthCenterChartOptions(question, chartType) {
            const healthCenters = Array.from(
                new Set(
                    question.responses.flatMap(response =>
                        response.health_centers.map(hc => hc.health_center)
                    )
                )
            );

            const generateSeriesData = (response) =>
                healthCenters.map(hcName => {
                    const hc = response.health_centers.find(hc => hc.health_center === hcName);
                    return hc ? hc.count : 0;
                });
            if (this.selectedLanguage === 1) {
                switch (chartType) {
                    case 'doughnut': {
                        const seriesData = question.responses.flatMap(response =>
                            response.health_centers.map(hc => ({
                                name: `${response.eng_response} - ${hc.health_center}`,
                                value: hc.count,
                            }))
                        );

                        return {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)',
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left',
                                data: seriesData.map(item => item.name),
                            },
                            series: [
                                {
                                    name: question.question,
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    label: { show: false, position: 'center' },
                                    emphasis: { label: { show: true, fontSize: '16', fontWeight: 'bold' } },
                                    labelLine: { show: false },
                                    data: seriesData,
                                    itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
                                },
                            ],
                            color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                        };
                    }

                    case 'polarEndAngle': {
                        const seriesData = question.responses.map(response => ({
                            name: response.eng_response,
                            type: 'bar',
                            data: generateSeriesData(response),
                            coordinateSystem: 'polar',
                        }));

                        return {
                            angleAxis: { type: 'category', data: healthCenters, startAngle: 75 },
                            radiusAxis: {},
                            polar: {},
                            tooltip: { trigger: 'item' },
                            series: seriesData,
                            color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166'],
                            legend: { orient: 'vertical', left: 'left', data: question.responses.map(r => r.eng_response) },
                        };
                    }

                    case 'stackedHorizontalBar': {
                        const seriesData = question.responses.map(response => ({
                            name: response.eng_response,
                            type: 'bar',
                            stack: 'total',
                            data: generateSeriesData(response),
                            itemStyle: { borderRadius: [0, 10, 10, 0] },
                        }));

                        return {
                            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
                            xAxis: { type: 'value' },
                            yAxis: {
                                type: 'category',
                                data: healthCenters,
                                axisLabel: { interval: 0, rotate: 30 },
                            },
                            legend: { data: question.responses.map(r => r.eng_response) },
                            series: seriesData,
                            color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166'],
                        };
                    }

                    case 'pie': {
                        const seriesData = question.responses.flatMap(response =>
                            response.health_centers.map(hc => ({
                                name: `${response.eng_response} - ${hc.health_center}`,
                                value: hc.count,
                            }))
                        );

                        return {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)',
                            },
                            series: [
                                {
                                    name: question.eng_question,
                                    type: 'pie',
                                    radius: '55%',
                                    avoidLabelOverlap: false,
                                    label: {
                                        show: true,
                                        position: 'outside',
                                        formatter: '{b}: {d}%',
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: { show: true },
                                    data: seriesData,
                                    itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
                                },
                            ],
                            color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                        };
                    }

                    case 'gradientBar': {
                        const seriesData = question.responses.map((response) => ({
                            name: response.eng_response,
                            value: response.health_centers.reduce((total, hc) => total + hc.count, 0),
                        }));

                        console.log('Gradient Bar - Series Data:', seriesData);

                        return {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow',
                                },
                                formatter: (params) => {
                                    const item = params[0];
                                    return `${seriesData[item.dataIndex].name}: ${item.value}`;
                                },
                            },
                            xAxis: {
                                type: 'category',
                                data: seriesData.map((item) =>
                                    item.name.length > 10 ? `${item.name.substring(0, 10)}...` : item.name
                                ),
                                axisLabel: {
                                    inside: false,
                                    color: '#fff',
                                    rotate: 0,
                                },
                                axisTick: { show: false },
                                axisLine: { show: false },
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: { show: false },
                                axisTick: { show: false },
                                axisLabel: { color: '#999' },
                                splitLine: { show: false },
                            },
                            series: [
                                {
                                    type: 'bar',
                                    data: seriesData.map((item) => item.value),
                                    showBackground: true,
                                    backgroundStyle: {
                                        color: 'rgba(220, 220, 220, 0.2)',
                                    },
                                    label: {
                                        show: true,
                                        position: 'inside',
                                        rotate: 90,
                                        verticalAlign: 'middle',
                                        align: 'center',
                                        color: '#fff',
                                        formatter: (params) => {
                                            return seriesData[params.dataIndex].name;
                                        },
                                    },
                                    itemStyle: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: '#83bff6' },
                                            { offset: 0.5, color: '#188df0' },
                                            { offset: 1, color: '#188df0' },
                                        ]),
                                        borderRadius: [10, 10, 0, 0],
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: '#2378f7' },
                                                { offset: 0.7, color: '#2378f7' },
                                                { offset: 1, color: '#83bff6' },
                                            ]),
                                        },
                                    },
                                    barWidth: '60%',
                                },
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                },
                            ],
                        };
                    }

                    case 'radar': {
                        const radarIndicators = healthCenters.map(hc => ({
                            name: hc,
                            max: Math.max(
                                ...question.responses.flatMap(response =>
                                    response.health_centers.map(hc => hc.count)
                                )
                            ) * 1.2,
                        }));

                        const radarSeriesData = question.responses.map(response => ({
                            value: generateSeriesData(response),
                            name: response.eng_response,
                        }));

                        return {
                            tooltip: {},
                            radar: {
                                indicator: radarIndicators,
                                radius: 60,
                            },
                            series: [
                                {
                                    type: 'radar',
                                    data: radarSeriesData,
                                    areaStyle: { opacity: 0.3 },
                                },
                            ],
                            color: ['#5470C6', '#FF5A5F', '#4C78A8', '#FFD166'],
                        };
                    }

                    default: {
                        const seriesData = question.responses.map(response => ({
                            name: response.eng_response,
                            type: chartType,
                            data: generateSeriesData(response),
                            barGap: "10%",
                            label: { show: false },
                            itemStyle: { borderRadius: [10, 10, 0, 0] },
                        }));

                        return {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: { type: 'shadow' },
                                extraCssText: 'z-index: 1000;',
                            },
                            legend: {
                                data: question.responses.map(response => response.eng_response),
                                top: 'top',
                                textStyle: {
                                    fontSize: 12,
                                },
                                padding: [10, 15],
                            },
                            grid: {
                                top: 80,
                                bottom: 80,
                                left: 50,
                                right: 30,
                            },
                            xAxis: {
                                type: 'category',
                                data: healthCenters,
                                axisLabel: {
                                    rotate: 45,
                                    interval: 0,
                                    fontSize: 10,
                                },
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: seriesData,
                            color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                        };
                    }
                }
            } else {
                switch (chartType) {
                    case 'doughnut': {
                        const seriesData = question.responses.flatMap(response =>
                            response.health_centers.map(hc => ({
                                name: `${response.response} - ${hc.health_center}`,
                                value: hc.count,
                            }))
                        );

                        return {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)',
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'left',
                                data: seriesData.map(item => item.name),
                            },
                            series: [
                                {
                                    name: question.question,
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    label: { show: false, position: 'center' },
                                    emphasis: { label: { show: true, fontSize: '16', fontWeight: 'bold' } },
                                    labelLine: { show: false },
                                    data: seriesData,
                                    itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
                                },
                            ],
                            color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                        };
                    }

                    case 'polarEndAngle': {
                        const seriesData = question.responses.map(response => ({
                            name: response.response,
                            type: 'bar',
                            data: generateSeriesData(response),
                            coordinateSystem: 'polar',
                        }));

                        return {
                            angleAxis: { type: 'category', data: healthCenters, startAngle: 75 },
                            radiusAxis: {},
                            polar: {},
                            tooltip: { trigger: 'item' },
                            series: seriesData,
                            color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166'],
                            legend: { orient: 'vertical', left: 'left', data: question.responses.map(r => r.response) },
                        };
                    }

                    case 'stackedHorizontalBar': {
                        const seriesData = question.responses.map(response => ({
                            name: response.response,
                            type: 'bar',
                            stack: 'total',
                            data: generateSeriesData(response),
                            itemStyle: { borderRadius: [0, 10, 10, 0] },
                        }));

                        return {
                            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
                            xAxis: { type: 'value' },
                            yAxis: {
                                type: 'category',
                                data: healthCenters,
                                axisLabel: { interval: 0, rotate: 30 },
                            },
                            legend: { data: question.responses.map(r => r.response) },
                            series: seriesData,
                            color: ['#FF5A5F', '#57CC99', '#4C78A8', '#FFD166'],
                        };
                    }

                    case 'pie': {
                        const seriesData = question.responses.flatMap(response =>
                            response.health_centers.map(hc => ({
                                name: `${response.response} - ${hc.health_center}`,
                                value: hc.count,
                            }))
                        );

                        return {
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b}: {c} ({d}%)',
                            },
                            series: [
                                {
                                    name: question.question,
                                    type: 'pie',
                                    radius: '55%',
                                    avoidLabelOverlap: false,
                                    label: {
                                        show: true,
                                        position: 'outside',
                                        formatter: '{b}: {d}%',
                                    },
                                    emphasis: {
                                        label: {
                                            show: true,
                                            fontSize: '16',
                                            fontWeight: 'bold'
                                        }
                                    },
                                    labelLine: { show: true },
                                    data: seriesData,
                                    itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
                                },
                            ],
                            color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                        };
                    }

                    case 'gradientBar': {
                        const seriesData = question.responses.map((response) => ({
                            name: response.response,
                            value: response.health_centers.reduce((total, hc) => total + hc.count, 0),
                        }));

                        console.log('Gradient Bar - Series Data:', seriesData);

                        return {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow',
                                },
                                formatter: (params) => {
                                    const item = params[0];
                                    return `${seriesData[item.dataIndex].name}: ${item.value}`;
                                },
                            },
                            xAxis: {
                                type: 'category',
                                data: seriesData.map((item) =>
                                    item.name.length > 10 ? `${item.name.substring(0, 10)}...` : item.name
                                ),
                                axisLabel: {
                                    inside: false,
                                    color: '#fff',
                                    rotate: 0,
                                },
                                axisTick: { show: false },
                                axisLine: { show: false },
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: { show: false },
                                axisTick: { show: false },
                                axisLabel: { color: '#999' },
                                splitLine: { show: false },
                            },
                            series: [
                                {
                                    type: 'bar',
                                    data: seriesData.map((item) => item.value),
                                    showBackground: true,
                                    backgroundStyle: {
                                        color: 'rgba(220, 220, 220, 0.2)',
                                    },
                                    label: {
                                        show: true,
                                        position: 'inside',
                                        rotate: 90,
                                        verticalAlign: 'middle',
                                        align: 'center',
                                        color: '#fff',
                                        formatter: (params) => {
                                            return seriesData[params.dataIndex].name;
                                        },
                                    },
                                    itemStyle: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: '#83bff6' },
                                            { offset: 0.5, color: '#188df0' },
                                            { offset: 1, color: '#188df0' },
                                        ]),
                                        borderRadius: [10, 10, 0, 0],
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: '#2378f7' },
                                                { offset: 0.7, color: '#2378f7' },
                                                { offset: 1, color: '#83bff6' },
                                            ]),
                                        },
                                    },
                                    barWidth: '60%',
                                },
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                },
                            ],
                        };
                    }

                    case 'radar': {
                        const radarIndicators = healthCenters.map(hc => ({
                            name: hc,
                            max: Math.max(
                                ...question.responses.flatMap(response =>
                                    response.health_centers.map(hc => hc.count)
                                )
                            ) * 1.2,
                        }));

                        const radarSeriesData = question.responses.map(response => ({
                            value: generateSeriesData(response),
                            name: response.response,
                        }));

                        return {
                            tooltip: {},
                            radar: {
                                indicator: radarIndicators,
                                radius: 60,
                            },
                            series: [
                                {
                                    type: 'radar',
                                    data: radarSeriesData,
                                    areaStyle: { opacity: 0.3 },
                                },
                            ],
                            color: ['#5470C6', '#FF5A5F', '#4C78A8', '#FFD166'],
                        };
                    }
                    default: {
                        const seriesData = question.responses.map(response => ({
                            name: response.response,
                            type: chartType,
                            data: generateSeriesData(response),
                            barGap: "10%",
                            label: { show: false },
                            itemStyle: { borderRadius: [10, 10, 0, 0] },
                        }));

                        return {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: { type: 'shadow' }
                            },
                            legend: {
                                data: question.responses.map(response => response.response),
                                top: 'top',
                                textStyle: {
                                    fontSize: 12,
                                },
                                padding: [10, 15],
                            },
                            grid: {
                                top: 80,
                                bottom: 80,
                                left: 50,
                                right: 30,
                            },
                            xAxis: {
                                type: 'category',
                                data: healthCenters,
                                axisLabel: {
                                    rotate: 45,
                                    interval: 0,
                                    fontSize: 10,
                                },
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: seriesData,
                            color: ['#FF5A5F', '#4C78A8', '#2E2E2E', '#FFD166', '#57CC99', '#1F78D1', '#FF9A76', '#F4D35E', '#E63946'],
                        };
                    }
                }
            }
        },
        resizeCharts() {
            const gridContainer = document.querySelector(".grid-container");
            if (!gridContainer) return;

            const gridWidth = gridContainer.offsetWidth; // Get the width of the grid container
            const colNum = 8; // Match the :col-num value
            const columnWidth = gridWidth / colNum; // Calculate column width

            this.dashboardData.charts_data.questions.forEach((question, index) => {
                const chartDomId = this.dashboardData.type === "hc_graph" ? `chart-question-${index}` : `chart-${index}`;
                const chartDom = document.getElementById(chartDomId);
                if (chartDom) {
                    const layoutItem = this.layout.find((item) => item.i === `question-${index}`);
                    if (layoutItem) {
                        // Calculate chart dimensions based on grid sizes
                        const width = layoutItem.w * columnWidth;
                        const height = layoutItem.h * 115; // Row height is 115

                        // Set the card container's dimensions
                        const card = chartDom.closest(".cards");
                        if (card) {
                            card.style.width = `${width}px`;
                            card.style.height = `${height}px`;
                        }

                        // Set the chart's dimensions
                        chartDom.style.width = `100%`; // Ensure the chart fills the card
                        chartDom.style.height = `calc(100% - 40px)`; // Adjust for padding or header height
                    }

                    const chartInstance = echarts.getInstanceByDom(chartDom);
                    if (chartInstance) {
                        chartInstance.resize();
                    }
                }
            });
        },
        saveChartSettings() {
            const chartTypes = {
                ...this.dashboardData.chart_types, // Include all existing chart types
            };

            this.dashboardData.charts_data.questions.forEach((question, index) => {
                const questionId = question.id;
                const layoutKey = `question-${index}`;
                chartTypes[questionId] = this.dashboardData.chart_types[layoutKey] || "bar";
            });

            const saveData = {
                charts_data: this.dashboardData.charts_data,
                chart_sizes: this.dashboardData.chart_sizes || {},
                chart_types: chartTypes,
            };

            console.log("saveChartSettings", saveData);

            axios
                .post(`/dashboard/${this.id}/save-settings`, saveData)
                .then((response) => {
                    if (response.data.status) {
                        Toast.success("Settings saved successfully");
                    } else {
                        Toast.error("Failed to save settings.");
                    }
                })
                .catch((error) => {
                    console.error("Error saving settings:", error);
                    Toast.error("An error occurred while saving settings.");
                });
        },

        downloadExcel() {
            try {
                // Prepare data for the Excel file
                const excelData = this.prepareExcelData();

                if (!excelData || excelData.length <= 1) {
                    console.warn("No data available for export");
                    return; // Abort download if there’s no valid data
                }

                const ws = XLSX.utils.aoa_to_sheet(excelData); // Create worksheet from array of arrays
                const wb = XLSX.utils.book_new(); // Create a new workbook

                XLSX.utils.book_append_sheet(wb, ws, "Health Center Report"); // Append the sheet

                // Set column widths for better readability
                ws['!cols'] = this.getColumnWidths();

                // Write the file
                XLSX.writeFile(wb, "health_center_report.xlsx");
            } catch (error) {
                console.error("Error while generating Excel file:", error);
            }
        },

        prepareExcelData() {
            const header = [
                "District",
                "Tool",
                "Condition",
                "Questions",
                "Responses",
                "Site Name",
                "Count"
            ];
            const data = [header];

            if (!this.tableData || this.tableData.length === 0) {
                console.warn("No table data available for Excel export");
                return data;
            }
            if (this.selectedLanguage === 1) {
                this.tableData.forEach((district) => {
                    district.tools.forEach((tool) => {
                        tool.conditions.forEach((condition) => {
                            condition.questions.forEach((question) => {
                                question.responses.forEach((response) => {
                                    response.sites.forEach((site) => {
                                        const row = [
                                            district.district_name || "",
                                            tool.tool_name || "",
                                            condition.condition_name || "",
                                            question.eng_question || "",
                                            response.eng_response || "",
                                            site.site_name || "",
                                            site.response_count || ""
                                        ];
                                        data.push(row);
                                    });
                                });
                            });
                        });
                    });
                });
                return data;
            }
            else {
                this.tableData.forEach((district) => {
                    district.tools.forEach((tool) => {
                        tool.conditions.forEach((condition) => {
                            condition.questions.forEach((question) => {
                                question.responses.forEach((response) => {
                                    response.sites.forEach((site) => {
                                        const row = [
                                            district.district_name || "",
                                            tool.kiny_tool_name || "",
                                            condition.condition_name || "",
                                            question.question || "",
                                            response.response || "",
                                            site.site_name || "",
                                            site.response_count || ""
                                        ];
                                        data.push(row);
                                    });
                                });
                            });
                        });
                    });
                });
                return data;
            }
        },


        getMerges() {
            const merges = [];
            let conditionStart = 1; // Starting row for conditions
            let questionStart = 1;  // Starting row for questions
            let responseStart = 1;  // Starting row for responses

            this.tableData.forEach(question => {
                const responseCount = this.getTotalRowspan(question.responses);

                // Merge Condition column
                merges.push({
                    s: { r: conditionStart, c: 0 },
                    e: { r: conditionStart + responseCount - 1, c: 0 }
                });

                // Merge Question column
                merges.push({
                    s: { r: questionStart, c: 1 },
                    e: { r: questionStart + responseCount - 1, c: 1 }
                });

                // Merge Response column
                question.responses.forEach(response => {
                    merges.push({
                        s: { r: responseStart, c: 2 },
                        e: { r: responseStart + response.health_centers.length - 1, c: 2 }
                    });
                    responseStart += response.health_centers.length;
                });

                conditionStart += responseCount;
                questionStart += responseCount;
            });

            return merges;
        },

        getTotalRowspan(responses) {
            return responses.reduce((total, response) => total + response.health_centers.length, 0);
        },

        getHealthCenterCount(centers, centerName, currentCenterName) {
            if (currentCenterName === centerName) {
                const center = centers.find(center => center.health_center === centerName);
                return center ? center.count : 0;
            }
            return 0;
        },

        getColumnWidths() {
            return [
                { wch: 15 }, // District
                { wch: 20 }, // Tool
                { wch: 20 }, // Condition
                { wch: 40 }, // Questions
                { wch: 25 }, // Responses
                { wch: 20 }, // Site Name
                { wch: 10 }  // Count
            ];
        }

    },
};
</script>



<style scoped>
.grid-container {
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
}

.vue-grid-layout {
    width: 100%;
    background: #f5f5f5;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.cards {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
}

.remove-button {
    background: transparent;
    border: none;
    color: #e74c3c;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
}

.remove-button:hover {
    color: #c0392b;
}

.filter-select select {
    padding: 8px 40px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.filter-select select:focus {
    outline: none;
    border-color: #888;
}

.question-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.chart-container {
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.save-button-container {
    margin: 0;
}

.save-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 13px 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.save-button:hover {
    background-color: #0056b3;
}

.save-button i {
    font-size: 18px;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    vertical-align: top;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

tbody tr:hover {
    background-color: #f1f1f1;
}

.download-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    margin-bottom: 12px;
}

.download-button:hover {
    background-color: #0056b3;
}

.download-button:active {
    background-color: #004085;
}

.custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 220px;
}

.custom-select {
    appearance: none;
    width: 100%;
    padding: 12px 16px;
    padding-right: 36px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.custom-select:hover {
    border-color: #007bff;
}

.custom-select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.3);
}

/* Custom Dropdown Arrow */
.custom-select-wrapper::after {
    content: "\25BC";
    font-size: 12px;
    color: #555;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.3s ease;
}

.custom-select-wrapper:hover::after {
    color: #007bff;
}

.loading-container {
    text-align: center;
    padding: 20px;
    font-size: 18px;
}
</style>
